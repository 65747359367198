import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { ChevronDown, ChevronRight } from 'lucide-react';
// Component imports

const OTPayInsuranceTab = ({ patient, patientBillingInfo }) => {
  // Basic patient info state
  const [firstName, setFirstName] = useState(patientBillingInfo?.ChildFirstName || '');
  const [lastName, setLastName] = useState(patientBillingInfo?.ChildLastName || '');
  const [insuranceCompany, setInsuranceCompany] = useState(patientBillingInfo?.InsuranceComp || '');
  const [dob, setDob] = useState(patientBillingInfo?.ChildDOB ? format(new Date(patientBillingInfo.ChildDOB), 'yyyy-MM-dd') : '');
  const [childMemIdNum, setChildMemIdNum] = useState(patientBillingInfo?.ChildMemIdNum || '');
  const [insurCompNum, setInsurCompNum] = useState(patientBillingInfo?.InsurCompNum || '');
  const [subscriberName, setSubscriberName] = useState(patientBillingInfo?.SubscriberName || '');
  const [subscriberDob, setSubscriberDob] = useState(patientBillingInfo?.SubscriberDOB || '');
  const [subscriberRelationship, setSubscriberRelationship] = useState(patientBillingInfo?.SubscriberRelationship || '');
  const [subscriberMemIdNum, setSubscriberMemIdNum] = useState(patientBillingInfo?.SubscriberMemIdNum || '');

  // OT-specific codes state
  const [rowValues, setRowValues] = useState({
    '97110': null,
    '97530': null,
    '97166': null,
    '97168': null,
  });

  // Network section states
  const [isInNetwork, setIsInNetwork] = useState(patientBillingInfo?.OT?.ViaIns?.InNetwork?.Applies ?? true);
  const [isFirstSectionOpen, setIsFirstSectionOpen] = useState(patientBillingInfo?.OT?.ViaIns?.InNetwork?.Applies ?? true);
  const [isSecondSectionOpen, setIsSecondSectionOpen] = useState(patientBillingInfo?.OT?.ViaIns?.OutOfNetwork?.Applies ?? false);

  // Network data states
  const [inNetworkData, setInNetworkData] = useState({
    Sessions: patientBillingInfo?.OT?.ViaIns?.InNetwork?.Sessions || '',
    IndDeductAmt: patientBillingInfo?.OT?.ViaIns?.InNetwork?.IndDeductAmt || '',
    FamDeductAmt: patientBillingInfo?.OT?.ViaIns?.InNetwork?.FamDeductAmt || '',
    WhichDeductApplies: patientBillingInfo?.OT?.ViaIns?.InNetwork?.WhichDeductApplies || '',
    DeductType: patientBillingInfo?.OT?.ViaIns?.InNetwork?.DeductType || '',
    ChargePerSess: patientBillingInfo?.OT?.ViaIns?.InNetwork?.ChargePerSess || '',
    CopayAmt: patientBillingInfo?.OT?.ViaIns?.InNetwork?.CopayAmt || '',
    AuthRequired: patientBillingInfo?.OT?.ViaIns?.InNetwork?.AuthRequired || '',
    HardMax: patientBillingInfo?.OT?.ViaIns?.InNetwork?.HardMax || '',
    ChecksToMember: patientBillingInfo?.OT?.ViaIns?.InNetwork?.ChecksToMember || '',
    DevDelayCov: patientBillingInfo?.OT?.ViaIns?.InNetwork?.DevDelayCov || '',
    AutismCov: patientBillingInfo?.OT?.ViaIns?.InNetwork?.AutismCov || '',
    Exclusions: patientBillingInfo?.OT?.ViaIns?.InNetwork?.Exclusions || '',
    AuthNotes: patientBillingInfo?.OT?.ViaIns?.InNetwork?.Notes || '',
    authorizations: patientBillingInfo?.OT?.ViaIns?.InNetwork?.Authorizations || []
  });

  const [outOfNetworkData, setOutOfNetworkData] = useState({
    Sessions: patientBillingInfo?.OT?.ViaIns?.OutOfNetwork?.Sessions || '',
    IndDeductAmt: patientBillingInfo?.OT?.ViaIns?.OutOfNetwork?.IndDeductAmt || '',
    FamDeductAmt: patientBillingInfo?.OT?.ViaIns?.OutOfNetwork?.FamDeductAmt || '',
    WhichDeductApplies: patientBillingInfo?.OT?.ViaIns?.OutOfNetwork?.WhichDeductApplies || '',
    DeductType: patientBillingInfo?.OT?.ViaIns?.OutOfNetwork?.DeductType || '',
    ChargePerSess: patientBillingInfo?.OT?.ViaIns?.OutOfNetwork?.ChargePerSess || '',
    CopayAmt: patientBillingInfo?.OT?.ViaIns?.OutOfNetwork?.CopayAmt || '',
    AuthRequired: patientBillingInfo?.OT?.ViaIns?.OutOfNetwork?.AuthRequired || '',
    HardMax: patientBillingInfo?.OT?.ViaIns?.OutOfNetwork?.HardMax || '',
    ChecksToMember: patientBillingInfo?.OT?.ViaIns?.OutOfNetwork?.ChecksToMember || '',
    DevDelayCov: patientBillingInfo?.OT?.ViaIns?.OutOfNetwork?.DevDelayCov || '',
    AutismCov: patientBillingInfo?.OT?.ViaIns?.OutOfNetwork?.AutismCov || '',
    Exclusions: patientBillingInfo?.OT?.ViaIns?.OutOfNetwork?.Exclusions || '',
    AuthNotes: patientBillingInfo?.OT?.ViaIns?.OutOfNetwork?.Notes || '',
    authorizations: patientBillingInfo?.OT?.ViaIns?.OutOfNetwork?.Authorizations || []
  });

  // Initialize codes from patientBillingInfo
  useEffect(() => {
    if (patientBillingInfo?.OT?.ViaIns?.Codes) {
      setRowValues({
        '97110': patientBillingInfo.OT.ViaIns.Codes['97110'] || null,
        '97530': patientBillingInfo.OT.ViaIns.Codes['97530'] || null,
        '97166': patientBillingInfo.OT.ViaIns.Codes['97166'] || null,
        '97168': patientBillingInfo.OT.ViaIns.Codes['97168'] || null,
      });
    }
  }, [patientBillingInfo]);

  // Event Handlers
  const handleCheckboxChange = (rowId, value) => {
    setRowValues(prev => ({
      ...prev,
      [rowId]: value === 'yes' ? 'Yes' : 'No'
    }));
  };

  const toggleNetwork = (network) => {
    if (network === 'in') {
      setIsInNetwork(true);
      setIsFirstSectionOpen(true);
      setIsSecondSectionOpen(false);
    } else {
      setIsInNetwork(false);
      setIsSecondSectionOpen(true);
      setIsFirstSectionOpen(false);
    }
  };

  const handleInputChange = (e, isInNetwork) => {
    const { name, value } = e.target;
    if (isInNetwork) {
      setInNetworkData(prev => ({ ...prev, [name]: value }));
    } else {
      setOutOfNetworkData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleAuthUpdate = (index, updatedAuth, isInNetwork) => {
    const updateData = (prevData) => {
      const newAuthorizations = [...prevData.authorizations];
      newAuthorizations[index] = updatedAuth;
      return { ...prevData, authorizations: newAuthorizations };
    };

    if (isInNetwork) {
      setInNetworkData(updateData);
    } else {
      setOutOfNetworkData(updateData);
    }
  };

  const handleAddAuthorization = (isInNetwork, event) => {
    event.preventDefault();
    
    const updateData = (prevData) => {
      const lastAuth = prevData.authorizations[prevData.authorizations.length - 1];
      const newId = lastAuth ? lastAuth.id + 1 : 1;
      
      const newAuth = {
        id: newId,
        authorizationNumber: "",
        status: "",
        startDate: "",
        endDate: "",
        sessionsApproved: "",
        sessionNumber: "",
        authNotes: ""
      };
  
      return {
        ...prevData,
        authorizations: [...prevData.authorizations, newAuth]
      };
    };
  
    if (isInNetwork) {
      setInNetworkData(updateData);
    } else {
      setOutOfNetworkData(updateData);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const data = {
      firstName,
      lastName,
      insuranceCompany,
      dob,
      childMemIdNum,
      insurCompNum,
      subscriberName,
      subscriberDob,
      subscriberRelationship,
      subscriberMemIdNum,
      isInNetwork,
      inNetworkData,
      outOfNetworkData,
      rowValues
    };

    try {
      const response = await fetch('/api/update-patient', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        console.log('Patient data updated successfully');
      } else {
        console.error('Error updating patient data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const AuthorizationCard = ({ auth, onUpdate }) => {
    const [isOpen, setIsOpen] = useState(false);

    const formatDateForInput = (dateString) => {
      if (!dateString) return '';
      try {
        const [month, day, year] = dateString.split('/');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      } catch (error) {
        console.error('Error formatting date:', dateString, error);
        return '';
      }
    };
  
    // Helper function to format dates back to MM/DD/YYYY
    const formatDateForSave = (dateString) => {
      if (!dateString) return '';
      try {
        const [year, month, day] = dateString.split('-');
        return `${month}/${day}/${year}`;
      } catch (error) {
        console.error('Error formatting date for save:', dateString, error);
        return '';
      }
    };

    const [localAuth, setLocalAuth] = useState({
      id: auth.id || '',
      authorizationNumber: auth.AuthNum || '', // Changed from authorizationNumber
      status: auth.AuthStatus || '', // Changed from status
      startDate: formatDateForInput(auth.AuthStartDate) || '',
      endDate: formatDateForInput(auth.AuthEndDate) || '',
      sessionsApproved: auth.NumSessApproved || '', // Changed from sessionsApproved
      sessionNumber: auth.SessNumber || '', // Changed from sessionNumber
      authNotes: auth.AuthNotes || '' // Changed from authNotes
    });

    useEffect(() => {
      setLocalAuth({
        id: auth.id || '',
        authorizationNumber: auth.AuthNum || '',
        status: auth.AuthStatus || '',
        startDate: format(new Date(auth.AuthStartDate), 'yyyy-MM-dd') || '',
        endDate: format(new Date(auth.AuthEndDate), 'yyyy-MM-dd') || '',
        sessionsApproved: auth.NumSessApproved || '',
        sessionNumber: auth.SessNumber || '',
        authNotes: auth.AuthNotes || ''
      });
    }, [auth]);

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setLocalAuth(prev => ({ ...prev, [name]: value}))
    };

    const handleSave = () => {
      // Convert dates back to MM/DD/YYYY format before saving
      const updatedAuth = {
        ...localAuth,
        AuthNum: localAuth.authorizationNumber,
        AuthStatus: localAuth.status,
        AuthStartDate: formatDateForSave(localAuth.startDate),
        AuthEndDate: formatDateForSave(localAuth.endDate),
        NumSessApproved: localAuth.sessionsApproved,
        SessNumber: localAuth.sessionNumber,
        AuthNotes: localAuth.authNotes
      };
      onUpdate(updatedAuth);
      setIsOpen(false);
    };

    const handleClose = () => {
      setIsOpen(false);
    }

    return (
      <>
        <div onClick={() => setIsOpen(true)} className="p-4 bg-white shadow rounded-lg cursor-pointer hover:shadow-md transition-shadow">
          <h4 className="font-bold mb-2 text-blue-500">Authorization {localAuth.id || '1'}</h4>
          <p> <span className='font-semibold'>Auth #: </span> {localAuth.authorizationNumber || 'N/A'}</p>
          <p><span className='font-semibold'>Sessions Approved: </span> {localAuth.sessionsApproved || 'N/A'}</p>
        </div>
        {isOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg w-full max-w-md max-h-[90vh] overflow-y-auto relative">
            <button 
              onClick={handleClose} 
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              aria-label="Close"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
              </svg>
            </button>
              <h3 className="text-xl font-semibold mb-4">Edit Authorization</h3>
              <div className='mb-2'>
                <label htmlFor="auth_num" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Authorization #</label>
                <input name="authorizationNumber" type="text" id="auth_num" value={localAuth.authorizationNumber || ''} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="UM12345"  required />
              </div>
              <div className='mb-2'>
                  <label htmlFor="auth_status" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Authorization Status</label>
                  <div className="">
                          <select name="authStatus" value={localAuth.status} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="auth_status">
                            <option value="pending">Pending</option>
                            <option value="approved">Approved</option>
                            <option value="denied">Denied</option>
                          </select>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                          </div>
                  </div>
                </div>
              <div className="mb-2">
                <label htmlFor="authStart" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Authorization Start Date</label>
                <input type="date" value={localAuth.startDate || ''} onChange={handleInputChange} name="authStart" id="authStart" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
              </div> 
              <div className="mb-2">
                <label htmlFor="authEnd" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Authorization End Date</label>
                <input type="date" value={localAuth.endDate || ''} onChange={handleInputChange} name="authEnd" id="authEnd" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
              </div> 
              <div className='mb-2'>
                <label htmlFor="sessAppr" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Number of Sessions Approved</label>
                <input name="sessionsApproved" type="text" id="sessAppr" value={localAuth.sessionsApproved || ''} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="10" required />
              </div>
              <div className='mb-2'>
                <label htmlFor="sessNum" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Session Number</label>
                <input name="sessionNumber" type="text" id="sessNum" value={localAuth.sessionNumber || ''} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="10" required />
              </div>
              <div className=''>
                <label htmlFor="authNote" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Auth Notes</label>
                <textarea name="authNotes" id="authNote" value={localAuth.authNotes} onChange={handleInputChange} rows="3" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Answer here if applicable..."></textarea>
              </div>
              <div className="flex justify-end mt-4 space-x-2">
              <button 
              onClick={handleClose}
              className="px-4 py-2 text-gray-600 bg-gray-100 rounded hover:bg-gray-200"
              >
                Cancel
              </button>
              <button 
                onClick={handleSave} 
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Save
              </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderToggleSwitch = (network) => (
    <label className="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        className="sr-only peer"
        checked={network === 'in' ? isInNetwork : !isInNetwork}
        onChange={() => toggleNetwork(network)}
      />
      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
      <span className="ml-3 text-sm font-medium text-gray-900">Applies</span>
    </label>
  );

  const renderSectionHeader = (title, isOpen, setIsOpen, network) => (
    <div className="flex items-center justify-between">
      <h2 className="text-pink-600 font-bold my-4">{title}</h2>
      <div className="flex items-center">
        {renderToggleSwitch(network)}
        <button onClick={() => setIsOpen(!isOpen)} className="ml-4">
          {isOpen ? <ChevronDown size={24} /> : <ChevronRight size={24} />}
        </button>
      </div>
    </div>
  );

  const renderNetworkSection = (data, isInNetwork) => (
    <div className="space-y-6">
      <div className="grid gap-6 md:grid-cols-3">
        {/* OT Sessions */}
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">
            <b className="text-red-500">OT</b> Sessions
          </label>
          <input
            type="number"
            name="Sessions"
            value={data.Sessions}
            onChange={(e) => handleInputChange(e, isInNetwork)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>

        {/* Individual Deductible */}
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">
            Individual Deductible Amount
          </label>
          <div className="relative">
            <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">$</span>
            <input
              type="number"
              name="IndDeductAmt"
              value={data.IndDeductAmt}
              onChange={(e) => handleInputChange(e, isInNetwork)}
              className="pl-8 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
        </div>

        {/* Family Deductible */}
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">
            Family Deductible Amount
          </label>
          <div className="relative">
            <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">$</span>
            <input
              type="number"
              name="FamDeductAmt"
              value={data.FamDeductAmt}
              onChange={(e) => handleInputChange(e, isInNetwork)}
              className="pl-8 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
        </div>

        {/* Which Deductible Applies */}
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">
            Which Deductible Applies
          </label>
          <select
            name="WhichDeductApplies"
            value={data.WhichDeductApplies}
            onChange={(e) => handleInputChange(e, isInNetwork)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option value="Individual">Individual Deductible Amount - {data.IndDeductAmt}</option>
            <option value="Family">Family Deductible Amoubt - {data.FamDeductAmt}</option>
          </select>
        </div>

        {/* Deductible Type */}
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">
            Deductible Type
          </label>
          <select
            name="DeductType"
            value={data.DeductType}
            onChange={(e) => handleInputChange(e, isInNetwork)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option value="Combined">Combined</option>
            <option value="Individual">Individual</option>
          </select>
        </div>

        {/* Charge Per Session */}
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">
            Charge Per Session Until Deductible Met
          </label>
          <div className="relative">
            <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">$</span>
            <input
              type="number"
              name="ChargePerSess"
              value={data.ChargePerSess}
              onChange={(e) => handleInputChange(e, isInNetwork)}
              className="pl-8 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
        </div>

        {/* Copay Amount */}
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">
            Copay Amount
          </label>
          <div className="relative">
            <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">$</span>
            <input
              type="number"
              name="CopayAmt"
              value={data.CopayAmt}
              onChange={(e) => handleInputChange(e, isInNetwork)}
              className="pl-8 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
        </div>

        {/* Authorization Required */}
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">
            Authorization Required
          </label>
          <select
            name="AuthRequired"
            value={data.AuthRequired}
            onChange={(e) => handleInputChange(e, isInNetwork)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* Hard Max */}
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">
            Hard Max
          </label>
          <select
            name="HardMax"
            value={data.HardMax}
            onChange={(e) => handleInputChange(e, isInNetwork)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* Checks to Member */}
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">
            Checks to Member
          </label>
          <select
            name="ChecksToMember"
            value={data.ChecksToMember}
            onChange={(e) => handleInputChange(e, isInNetwork)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </select>
        </div>

        {/* Coverage Fields */}
        <div className="">
          <label className="block mb-2 text-sm font-medium text-gray-900">
            Is Developmental Delay Covered?
          </label>
          <input
            type="text"
            name="DevDelayCov"
            value={data.DevDelayCov}
            onChange={(e) => handleInputChange(e, isInNetwork)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>

        <div className="">
          <label className="block mb-2 text-sm font-medium text-gray-900">
            Is Autism Covered (And If So, Do The Benefits Change)?
          </label>
          <input
            type="text"
            name="AutismCov"
            value={data.AutismCov}
            onChange={(e) => handleInputChange(e, isInNetwork)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>

        <div className="col-span-3">
          <label className="block mb-2 text-sm font-medium text-gray-900">
            Exclusions
          </label>
          <input
            type="text"
            name="Exclusions"
            value={data.Exclusions}
            onChange={(e) => handleInputChange(e, isInNetwork)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>
      </div>

      {/* Authorizations Section */}
      <div className="mt-6">
        <h3 className="text-lg font-semibold mb-4">Authorizations</h3>
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {data.authorizations.map((auth, index) => (
            <AuthorizationCard
              key={auth.id || index}
              auth={auth}
              onUpdate={(updatedAuth) => handleAuthUpdate(index, updatedAuth, isInNetwork)}
            />
          ))}
          <button
            onClick={(e) => handleAddAuthorization(isInNetwork, e)}
            className="flex items-center justify-center h-40 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
          >
            <svg className="w-8 h-8 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
          </button>
        </div>
      </div>

      {/* Notes Section */}
      <div className="mt-4">
        <label className="block mb-2 text-sm font-medium text-gray-900">Notes</label>
        <textarea
          name="AuthNotes"
          value={data.AuthNotes}
          onChange={(e) => handleInputChange(e, isInNetwork)}
          rows="4"
          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Add any additional notes here..."
        />
      </div>
    </div>
  );

  if (!patient) {
    return <div>Loading patient data...</div>;
  }

  return (
    <div className="space-y-6">
      {/* Basic Information Form */}
      <div className="grid gap-6 mb-6 md:grid-cols-4">
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">Child First Name</label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>

        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">Child Last Name</label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>

        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">Child DOB</label>
          <input
            type="date"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>

        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">Insurance Company</label>
          <input
            type="text"
            value={insuranceCompany}
            onChange={(e) => setInsuranceCompany(e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>
        <div>
            <label htmlFor="child_mem_id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Child Member ID #</label>
            <input type="text" id="child_mem_id" value={childMemIdNum} onChange={(e) => setChildMemIdNum(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
        </div>
        <div>
            <label htmlFor="insur_comp_id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Insurance Company #</label>
            <input type="text" id="insur_comp_id" value={insurCompNum} onChange={(e) => setInsurCompNum(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
        </div>
        <div>
            <label htmlFor="sub_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Subscriber Name</label>
            <input type="text" id="sub_name" value={subscriberName} onChange={(e) => setSubscriberName(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
        </div>
        <div>
            <label htmlFor="sub_dob" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Subscriber DOB</label>
            <input type="text" id="sub_dob" value={subscriberDob} onChange={(e) => setSubscriberDob(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
        </div>
        <div>
            <label htmlFor="relation" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Relationship to Dependent</label>
            <div className="">
                    <select value={subscriberRelationship} onChange={(e) => setSubscriberRelationship(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="relation">
                    <option>Mother</option>
                    <option>Father</option>
                    <option>Grandmother</option>
                    <option>Grandfather</option>
                    <option>Sister</option>
                    <option>Brother</option>
                    <option>Aunt</option>
                    <option>Uncle</option>
                    <option>Step-parent</option>
                    <option>Other</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
            </div>
        </div>
        <div>
            <label htmlFor="sub_mem_id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Subscriber Member ID #</label>
            <input type="text" id="sub_mem_id" value={subscriberMemIdNum} onChange={(e) => setSubscriberMemIdNum(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
        </div>
      </div>

      {/* Network Sections */}
      <div>
        {renderSectionHeader("In Network", isFirstSectionOpen, setIsFirstSectionOpen, 'in')}
        {isFirstSectionOpen && renderNetworkSection(inNetworkData, true)}

        {renderSectionHeader("Out of Network", isSecondSectionOpen, setIsSecondSectionOpen, 'out')}
        {isSecondSectionOpen && renderNetworkSection(outOfNetworkData, false)}
      </div>

      {/* CPT Codes Table */}
      <div className="mt-8">
        <div className="overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3">OT Code</th>
                <th scope="col" className="px-6 py-3">Yes</th>
                <th scope="col" className="px-6 py-3">No</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(rowValues).map(([code, value]) => (
                <tr key={code} className="bg-white border-b">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    {code}
                  </th>
                  <td className="px-6 py-4">
                    <input
                      type="checkbox"
                      checked={value === 'Yes'}
                      onChange={() => handleCheckboxChange(code, 'yes')}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                    />
                  </td>
                  <td className="px-6 py-4">
                    <input
                      type="checkbox"
                      checked={value === 'No'}
                      onChange={() => handleCheckboxChange(code, 'no')}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Policy Dates */}
      <div className="grid gap-6 md:grid-cols-1">
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">
            Policy Effective Date
          </label>
          <input
            type="date"
            name="effectiveDate"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>

        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">
            Policy Termination Date
          </label>
          <input
            type="date"
            name="terminationDate"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>

        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">
            When Does Deductible Renew
          </label>
          <input
            type="date"
            name="deductibleRenewalDate"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>
      </div>

      {/* Submit Button */}
      <form onSubmit={handleSubmit}>
        <button
          type="submit"
          className="mt-6 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default OTPayInsuranceTab;