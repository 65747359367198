import React, { useState, useEffect, useCallback } from 'react';
import { format, parseISO, isValid, addDays, subDays } from 'date-fns';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import { Calendar } from 'lucide-react';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon} from '@heroicons/react/24/outline';
import axios from 'axios';

const EventsTable = ({ events, status }) => {
    const navigate = useNavigate();
    const [patientNames, setPatientNames] = useState({});
    const [patientBillingInfo, setPatientBillingInfo] = useState({});

    const calculateAmountAndPath = (event, billingInfo, status) => {
        // For No Show events, return fixed amount with no path
        if (status === 'No Show') {
            return { amount: 25, billingPath: null };
        }
        
        if (!billingInfo || !event?.event_name) {
            return { amount: 'Loading...', billingPath: null };
        }
        
        const service = getServiceName(event.event_name);
        const serviceKey = service === 'Occupational Therapy' ? 'OT' : 'ST';
    
        if (!billingInfo[serviceKey]) {
            return { 
                amount: `This patient doesn't have billing info for service ${serviceKey}`,
                billingPath: null 
            };
        }
        
        const serviceInfo = billingInfo[serviceKey];
        
        if (!serviceInfo.PrimaryMethod) {
            return { 
                amount: `This patient doesn't have service info primary method`,
                billingPath: null 
            };
        }
    
        if (serviceInfo.PrimaryMethod === 'Out Of Pocket') {
            return {
                amount: serviceInfo.ViaOop?.OopRate || 'N/A',
                billingPath: `${serviceKey}.ViaOop.OopRate`
            };
        }
    
        if (serviceInfo.PrimaryMethod === 'Insurance') {
            const insuranceInfo = serviceInfo.ViaIns;
            const isInNetwork = insuranceInfo.InNetwork.Applies;
            const networkType = isInNetwork ? 'InNetwork' : 'OutOfNetwork';
            const networkInfo = insuranceInfo[networkType];
    
            if (!networkInfo) return { amount: 'Loading...', billingPath: null };
    
            const deductType = networkInfo.WhichDeductApplies === 'Individual' ? 'Ind' : 'Fam';
            const deductKey = `${deductType}DeductAmt`;
    
            return {
                amount: networkInfo.ChargePerSess,
                billingPath: `${serviceKey}.ViaIns.${networkType}.${deductKey}`
            };
        }
    
        return { amount: 'N/A', billingPath: null };
    };

    const fetchBillingInfo = async (patientId) => {
        try {
            const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/find_patient_billing_info', {
                headers: {
                    'PatientID': patientId
                }
            });
            
            const data = await response.json();
            if (data.statusCode === 200) {
                const billingInfo = JSON.parse(data.body)[0];
                setPatientBillingInfo(prev => ({
                    ...prev,
                    [patientId]: billingInfo
                }));
            }
        } catch (error) {
            console.error('Error fetching billing info:', error);
        }
    };

    useEffect(() => {
        events.forEach(event => {
            if (event.patient_id && !patientBillingInfo[event.patient_id]) {
                fetchBillingInfo(event.patient_id);
            }
        });
    }, [events]);

    const fetchPatientInfo = useCallback(async (patientId) => {
        if (patientId && patientId !== 'null') {
          //console.log(`Fetching patient info for ID: ${patientId}`);
          try {
            const response = await axios.get('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/find_patient', {
              headers: {
                PatientID: patientId,
                'Content-Type': 'application/json'
              }
            });
            
            //console.log('Raw response data:', response.data);
      
            if (response.data && response.data.body) {
              // Parse the JSON string in the body
              const parsedBody = JSON.parse(response.data.body);
              console.log('Parsed body:', parsedBody);
      
              if (Array.isArray(parsedBody) && parsedBody.length > 0 && parsedBody.length < 2) {
                const patientData = parsedBody[0];
                if (patientData.FirstName && patientData.LastName) {
                  //console.log(`Patient info received for ID ${patientId}: ${patientData.FirstName} ${patientData.LastName}`);
                  return {
                    firstName: patientData.FirstName,
                    lastName: patientData.LastName
                  };
                } else {
                  console.log(`Incomplete patient data received for ID ${patientId}`);
                }
              } else if (Array.isArray(parsedBody) && parsedBody.length > 1) {
                const patientData = parsedBody[1];
                if (patientData.FirstName && patientData.LastName) {
                  console.log(`Patient info received for ID ${patientId}: ${patientData.FirstName} ${patientData.LastName}`);
                  return {
                    firstName: patientData.FirstName,
                    lastName: patientData.LastName
                  };
                } else {
                  console.log(`Incomplete patient data received for ID ${patientId}`);
                }
              } else {
                console.log(`No patient data found in the response for ID ${patientId}`);
              }
            } else {
              console.log(`Unexpected response structure for ID ${patientId}`);
            }
          } catch (error) {
            console.error('Error fetching patient info:', error);
            // Log the full error response for debugging
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          }
        } else {
          console.log(`Invalid patient ID: ${patientId}`);
        }
        return null;
      }, []);

      useEffect(() => {
        const fetchPatientNames = async () => {
            const namesMap = {};
            for (const event of events) {
                if (event.patient_id && !patientNames[event.patient_id]) {
                    //console.log('Fetching for patient ID:', event.patient_id);
                    const patientInfo = await fetchPatientInfo(event.patient_id);
                    //console.log('Received patientInfo:', patientInfo);
                    
                    if (patientInfo && patientInfo.firstName && patientInfo.lastName) {
                        namesMap[event.patient_id] = `${patientInfo.lastName}, ${patientInfo.firstName}`;
                        //console.log('Set name for patient:', event.patient_id, namesMap[event.patient_id]);
                    }
                }
            }
            console.log('Final namesMap:', namesMap);
            setPatientNames(prevNames => {
                const newNames = { ...prevNames, ...namesMap };
                //console.log('Updated patientNames state:', newNames);
                return newNames;
            });
        };
        
        fetchPatientNames();
    }, [events, fetchPatientInfo]);
    
    //console.log('Current patientNames state:', patientNames);
    
    const handleButtonClick = (event) => {
        if (status === 'Scheduled') {
            console.log('Alert Provider clicked for:', event);
        } else {
            const {amount, billingPath } = calculateAmountAndPath(event, patientBillingInfo[event.patient_id], status);
            console.log("Sending to charge page:", {event, status, amount, billingPath});
            navigate('/Charge', { 
                state: { 
                    eventData: event,
                    status: status, 
                    amount: amount,
                    billingPath: billingPath
                } 
            });
        }
    };

    return (
        <div className="overflow-x-auto">
            <table className="w-full border-collapse">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="p-4 text-left font-semibold text-gray-600">Provider</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Service</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Patient ID</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Patient Name</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Date</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Time</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Status</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Amount</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {events.map((event, index) => {
                        const { date, time } = formatDateTime(
                            event.start_time,
                            event.end_time
                        );

                        const { amount } = calculateAmountAndPath(event, patientBillingInfo[event.patient_id], status);
                        
                        return (
                            <tr key={index} className="border-b border-gray-200 hover:bg-gray-50">
                                <td className="p-4">{event.pr_id}</td>
                                <td className="p-4">{getServiceName(event.event_name)}</td>
                                <td className="p-4">{event.patient_id}</td>
                                <td className="p-4">{patientNames[event.patient_id] || 'Loading...'}</td>
                                <td className="p-4">{date}</td>
                                <td className="p-4">{time}</td>
                                <td className="p-4">{status}</td>
                                <td className="p-4">${typeof amount === 'number' ? amount.toFixed(2) : amount}</td>
                                <td className="p-4">
                                    <button
                                        onClick={() => handleButtonClick(event)}
                                        className={`px-4 py-2 rounded-lg text-white ${
                                            status === 'Scheduled'
                                                ? 'bg-yellow-500 hover:bg-yellow-600'
                                                : 'bg-green-500 hover:bg-green-600'
                                        }`}
                                    >
                                        {status === 'Scheduled' ? 'Alert Provider' : 'Charge'}
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

const CustomersTable = () => {
    const [customers, setCustomers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await fetch('https://your-api-endpoint/customers', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();
                setCustomers(data);
            } catch (error) {
                console.error('Error fetching customers:', error);
            }
        };

        fetchCustomers();
    }, []);

    const handleAddCustomer = () => {
        setModalType('addCustomer');
        setShowModal(true);
    };

    const handleCardAction = (customer, action) => {
        setSelectedCustomer(customer);
        setModalType(action);
        setShowModal(true);
    };

    return (
        <div className="overflow-x-auto">
            <div className="flex justify-end mb-4">
                <button
                    onClick={handleAddCustomer}
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                    Add Customer
                </button>
            </div>
            <table className="w-full border-collapse">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="p-4 text-left font-semibold text-gray-600">Name</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Email</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Card</th>
                    </tr>
                </thead>
                <tbody>
                    {customers.map((customer, index) => (
                        <tr key={index} className="border-b border-gray-200 hover:bg-gray-50">
                            <td className="p-4">{customer.name}</td>
                            <td className="p-4">{customer.email}</td>
                            <td className="p-4">
                                <button
                                    onClick={() => handleCardAction(customer, customer.card ? 'editCard' : 'addCard')}
                                    className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                                >
                                    {customer.card ? 'Edit Card' : 'Add Card'}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {showModal && (
                <div className="modal"> {/* Replace with actual modal code */}
                    <h2>{modalType === 'addCustomer' ? 'Add Customer' : modalType === 'editCard' ? 'Edit Card' : 'Add Card'}</h2>
                    {/* Modal content goes here */}
                    <button onClick={() => setShowModal(false)}>Close</button>
                </div>
            )}
        </div>
    );
};

const ChargesTable = () => {
    const [charges, setCharges] = useState([]);

    useEffect(() => {
        const fetchCharges = async () => {
            try {
                const response = await fetch('https://your-api-endpoint/charges', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();
                setCharges(data);
            } catch (error) {
                console.error('Error fetching charges:', error);
            }
        };

        fetchCharges();
    }, []);

    return (
        <div className="overflow-x-auto">
            <table className="w-full border-collapse">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="p-4 text-left font-semibold text-gray-600">Patient Name</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Service Date</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Amount Charged</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {charges.map((charge, index) => (
                        <tr key={index} className="border-b border-gray-200 hover:bg-gray-50">
                            <td className="p-4">{charge.patientName}</td>
                            <td className="p-4">{format(new Date(charge.serviceDate), 'MMMM d, yyyy')}</td>
                            <td className="p-4">${charge.amountCharged}</td>
                            <td className="p-4">{charge.status}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const getServiceName = (evName) => {
    switch (evName) {
        case 'OT Appointment':
            return 'Occupational Therapy';
        case 'ST Appointment':
            return 'Speech Therapy';
        default:
            return 'N/A';
    }
};

const formatDateTime = (startTime, endTime) => {
    try {
        if (!startTime || !endTime) {
            return { date: 'N/A', time: 'N/A' };
        }
    
        const start = parseISO(startTime);
        const end = parseISO(endTime);
        
        if (!isValid(start) || !isValid(end)) {
            return { date: 'N/A', time: 'N/A' };
        }
    
        return {
            date: format(start, 'MMMM do'),
            time: `${format(start, 'h:mm')}-${format(end, 'h:mm')}`
        };
    } catch (error) {
        console.error('Error formatting date time:', error);
        return { date: 'N/A', time: 'N/A' };
    }
};

const DateSelector = ({ selectedDate, onDateChange }) => {
    return (
        <div className="flex items-center gap-4 mt-4 mb-2">
            <button
                onClick={() => onDateChange(subDays(selectedDate, 1))}
                className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 flex items-center gap-2"
            >
                <ChevronDoubleLeftIcon className='w-4 h-5' />
            </button>
            
            <div className="flex items-center gap-2 bg-gray-100 px-4 py-2 rounded-lg">
                <Calendar className="w-5 h-5 text-gray-500" />
                <span className="font-medium">{format(selectedDate, 'MMMM d, yyyy')}</span>
            </div>

            <button
                onClick={() => onDateChange(addDays(selectedDate, 1))}
                className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 flex items-center gap-2"
            >
                <ChevronDoubleRightIcon className='w-4 h-5' />
            </button>

            <button
                onClick={() => onDateChange(new Date())}
                className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 flex items-center gap-2"
            >
                Today
            </button>
        </div>
    );
};

const ChargingTable = () => {
    const [activeTab, setActiveTab] = useState('confirmed');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [scheduledEvents, setScheduledEvents] = useState([]);
    const [confirmedEvents, setConfirmedEvents] = useState([]);
    const [noShowEvents, setNoShowEvents] = useState([]);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const formattedDate = format(selectedDate, 'yyyy-MM-dd');
        
                const requestBody = {
                    start_date: formattedDate,
                    end_date: formattedDate
                };
        
                const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get_chargeable_events', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody)
                });
        
                const data = await response.json();
                const eventData = typeof data.body === 'string' ? JSON.parse(data.body) : data.body;
                
                setScheduledEvents(eventData.filter(event => event.ev_status === 'A'));
                setConfirmedEvents(eventData.filter(event => event.ev_status === 'Y'));
                setNoShowEvents(eventData.filter(event => event.ev_status === 'N'));
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };
    
        fetchEvents();
    }, [selectedDate]);

    return (
        <div>
            <Header />
            <div className="p-6 max-w-7xl mx-auto mt-12">
                <DateSelector 
                    selectedDate={selectedDate}
                    onDateChange={setSelectedDate}
                />
                
                <div className="mb-8">
                    <div className="border-b border-gray-200">
                        <nav className="flex -mb-px">
                            <button
                                onClick={() => setActiveTab('confirmed')}
                                className={`mr-8 py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'confirmed'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                                {confirmedEvents.length} Confirmed
                            </button>
                            <button
                                onClick={() => setActiveTab('scheduled')}
                                className={`mr-8 py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'scheduled'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                                {scheduledEvents.length} Scheduled
                            </button>
                            <button
                                onClick={() => setActiveTab('noshow')}
                                className={`mr-8 py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'noshow'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                                {noShowEvents.length} No Show
                            </button>
                            <button
                                onClick={() => setActiveTab('customers')}
                                className={`mr-8 ml-auto py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'customers'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                                 Customers
                            </button>
                            <button
                                onClick={() => setActiveTab('charges')}
                                className={`py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'charges'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                                 Charges
                            </button>
                        </nav>
                    </div>
                </div>

                <div className="mt-6">
                    {activeTab === 'confirmed' && (
                        <EventsTable events={confirmedEvents} status="Confirmed" />
                    )}
                    {activeTab === 'scheduled' && (
                        <EventsTable events={scheduledEvents} status="Scheduled" />
                    )}
                    {activeTab === 'noshow' && (
                        <EventsTable events={noShowEvents} status="No Show" />
                    )}
                    {activeTab === 'customers' && (
                        <CustomersTable />
                    )}
                    {activeTab === 'charges' && (
                        <ChargesTable />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChargingTable;