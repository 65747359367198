import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import format from 'date-fns/format';
import { ChevronDown, ChevronRight } from 'lucide-react';

const STPayInsuranceTab = ({ patient, patientBillingInfo }) => {
  const [firstName, setFirstName] = useState(patientBillingInfo?.ChildFirstName || '');
  const [lastName, setLastName] = useState(patientBillingInfo?.ChildLastName || '');
  const [insuranceCompany, setInsuranceCompany] = useState(patientBillingInfo?.InsuranceComp || '');
  const [dob, setDob] = useState(format(patientBillingInfo?.ChildDOB, 'yyyy-MM-dd') || '');
  const [childMemIdNum, setChildMemIdNum] = useState(patientBillingInfo?.ChildMemIdNum || '');
  const [insurCompNum, setInsurCompNum] = useState(patientBillingInfo?.InsurCompNum || '');
  const [subscriberName, setSubscriberName] = useState(patientBillingInfo?.SubscriberName || '');
  const [subscriberDob, setSubscriberDob] = useState(patientBillingInfo?.SubscriberDOB || '');
  const [subscriberRelationship, setSubscriberRelationship] = useState(patientBillingInfo?.SubscriberRelationship || '');
  const [subscriberMemIdNum, setSubscriberMemIdNum] = useState(patientBillingInfo?.SubscriberMemIdNum || '');

  const [rowValues, setRowValues] = useState({
    '92507': null,
    '97535': null,
    '92526': null,
    'Speech Eval': null,
    '99213': null,
    '92610': null,
    '92523': null,
    '99366': null,
    '92524': null,
    'Only for Intake 92521 and 99203': null,
  });

  const [isInNetwork, setIsInNetwork] = useState(patientBillingInfo?.ST?.ViaIns?.InNetwork?.Applies ?? true); 
  const [isFirstSectionOpen, setIsFirstSectionOpen] = useState(patientBillingInfo?.ST?.ViaIns?.InNetwork?.Applies ?? true);
  const [isSecondSectionOpen, setIsSecondSectionOpen] = useState(patientBillingInfo?.ST?.ViaIns?.OutOfNetwork?.Applies ?? false);

  const [inNetworkData, setInNetworkData] = useState({
    Sessions: patientBillingInfo?.ST?.ViaIns?.InNetwork?.Sessions || '',
    IndDeductAmt: patientBillingInfo?.ST?.ViaIns?.InNetwork?.IndDeductAmt || '',
    FamDeductAmt: patientBillingInfo?.ST?.ViaIns?.InNetwork?.FamDeductAmt || '',
    WhichDeductApplies: patientBillingInfo?.ST?.ViaIns?.InNetwork?.WhichDeductApplies || '',
    DeductType: patientBillingInfo?.ST?.ViaIns?.InNetwork?.DeductType || '',
    ChargePerSess: patientBillingInfo?.ST?.ViaIns?.InNetwork?.ChargePerSess || '',
    CopayAmt: patientBillingInfo?.ST?.ViaIns?.InNetwork?.CopayAmt || '',
    AuthRequired: patientBillingInfo?.ST?.ViaIns?.InNetwork?.AuthRequired || '',
    HardMax: patientBillingInfo?.ST?.ViaIns?.InNetwork?.HardMax || '',
    ChecksToMember: patientBillingInfo?.ST?.ViaIns?.InNetwork?.ChecksToMember || '',
    DevDelayCov: patientBillingInfo?.ST?.ViaIns?.InNetwork?.DevDelayCov || '',
    AutismCov: patientBillingInfo?.ST?.ViaIns?.InNetwork?.AutismCov || '',
    Exclusions: patientBillingInfo?.ST?.ViaIns?.InNetwork?.Exclusions || '',
    AuthNotes: patientBillingInfo?.ST?.ViaIns?.InNetwork?.Notes || '',
    authorizations: patientBillingInfo?.ST?.ViaIns?.InNetwork?.Authorizations || []
  });
  
  const [outOfNetworkData, setOutOfNetworkData] = useState({
    Sessions: patientBillingInfo?.ST?.ViaIns?.OutOfNetwork?.Sessions || '',
    IndDeductAmt: patientBillingInfo?.ST?.ViaIns?.OutOfNetwork?.IndDeductAmt || '',
    FamDeductAmt: patientBillingInfo?.ST?.ViaIns?.OutOfNetwork?.FamDeductAmt || '',
    WhichDeductApplies: patientBillingInfo?.ST?.ViaIns?.OutOfNetwork?.WhichDeductApplies || '',
    DeductType: patientBillingInfo?.ST?.ViaIns?.OutOfNetwork?.DeductType || '',
    ChargePerSess: patientBillingInfo?.ST?.ViaIns?.OutOfNetwork?.ChargePerSess || '',
    CopayAmt: patientBillingInfo?.ST?.ViaIns?.OutOfNetwork?.CopayAmt || '',
    AuthRequired: patientBillingInfo?.ST?.ViaIns?.OutOfNetwork?.AuthRequired || '',
    HardMax: patientBillingInfo?.ST?.ViaIns?.OutOfNetwork?.HardMax || '',
    ChecksToMember: patientBillingInfo?.ST?.ViaIns?.OutOfNetwork?.ChecksToMember || '',
    DevDelayCov: patientBillingInfo?.ST?.ViaIns?.OutOfNetwork?.DevDelayCov || '',
    AutismCov: patientBillingInfo?.ST?.ViaIns?.OutOfNetwork?.AutismCov || '',
    Exclusions: patientBillingInfo?.ST?.ViaIns?.OutOfNetwork?.Exclusions || '',
    AuthNotes: patientBillingInfo?.ST?.ViaIns?.OutOfNetwork?.Notes || '',
    authorizations: patientBillingInfo?.ST?.ViaIns?.OutOfNetwork?.Authorizations || []
  });

  useEffect(() => {
    if (patientBillingInfo?.ST?.ViaIns?.Codes) {
      const codes = patientBillingInfo.ST.ViaIns.Codes;
      setRowValues({
        '92507': codes['92507'],
        '97535': codes['97535'],
        '92526': codes['92526'],
        'Speech Eval': codes['SpeechEval'],
        '99213': codes['99213'],
        '92610': codes['92610'],
        '92523': codes['92523'],
        '99366': codes['99366'],
        '92524': codes['92524'],
        'Only for Intake 92521 and 99203': codes['9252199203']
      });
    }
  }, [patientBillingInfo]);

  const handleCheckboxChange = (rowId, value) => {
    setRowValues(prevState => ({
      ...prevState,
      [rowId]: value === 'yes' ? 'Yes' : 'No'
    }));
  };

  const toggleNetwork = (network) => {
    if (network === 'in') {
      setIsInNetwork(true);
      setIsFirstSectionOpen(true);
    } else {
      setIsInNetwork(false);
      setIsSecondSectionOpen(true);
    }
  }

  const handleInputChange = (e, isInNetwork) => {
    const { name, value } = e.target;
    if (isInNetwork) {
      setInNetworkData((prevData) => ({ ...prevData, [name]: value }));
    } else {
      setOutOfNetworkData((prevData) => ({ ...prevData, [name]: value }));
    }
  };  
    
    const handleSubmit = async (e) => {
        e.preventDefault();
      
        // Prepare the data to send to the API
        const data = {
          firstName,
          lastName,         
          insuranceCompany,
          isInNetwork
        };
      
        try {
          // Send the data to the API Gateway endpoint
          const response = await fetch('/api/update-patient', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          });
      
          // Handle the response from the API
          if (response.ok) {
            console.log('Patient data updated successfully');
          } else {
            console.error('Error updating patient data');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };

    const renderToggleSwitch = (network) => (
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          className="sr-only peer"
          checked={network === 'in' ? isInNetwork : !isInNetwork}
          onChange={() => toggleNetwork(network)}
        />
        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
        <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Applies</span>
      </label>
    );
  
    const renderSectionHeader = (title, isOpen, setIsOpen, network) => (
      <div className="flex items-center justify-between">
        <h2 className="text-pink-600 font-bold my-4">{title}</h2>
        <div className="flex items-center">
          {renderToggleSwitch(network)}
          <button type="button" onClick={() => setIsOpen(!isOpen)} className="ml-4">
            {isOpen ? <ChevronDown size={24} /> : <ChevronRight size={24} />}
          </button>
        </div>
      </div>
    );

    const handleAuthUpdate = (index, updatedAuth, isInNetwork) => {
      const updateData = (prevData) => {
        const newAuthorizations = [...prevData.authorizations];
        newAuthorizations[index] = updatedAuth;
        return { ...prevData, authorizations: newAuthorizations };
      };
    
      if (isInNetwork) {
        setInNetworkData(updateData);
      } else {
        setOutOfNetworkData(updateData);
      }
    };
    
    const handleAddAuthorization = (isInNetwork, event) => {
      event.preventDefault();
      
      const updateData = (prevData) => {
        const lastAuth = prevData.authorizations[prevData.authorizations.length - 1];
        const newId = lastAuth ? lastAuth.id + 1 : 1;
        
        const newAuth = {
          id: newId,
          authorizationNumber: "",
          status: "",
          startDate: "",
          endDate: "",
          sessionsApproved: "",
          sessionNumber: "",
          authNotes: ""
        };
    
        return {
          ...prevData,
          authorizations: [...prevData.authorizations, newAuth]
        };
      };
    
      if (isInNetwork) {
        setInNetworkData(updateData);
      } else {
        setOutOfNetworkData(updateData);
      }
    };
    
    

    const AuthorizationCard = ({ auth, onUpdate }) => {
      const [isOpen, setIsOpen] = useState(false);

      const formatDateForInput = (dateString) => {
        if (!dateString) return '';
        try {
          const [month, day, year] = dateString.split('/');
          return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        } catch (error) {
          console.error('Error formatting date:', dateString, error);
          return '';
        }
      };
    
      // Helper function to format dates back to MM/DD/YYYY
      const formatDateForSave = (dateString) => {
        if (!dateString) return '';
        try {
          const [year, month, day] = dateString.split('-');
          return `${month}/${day}/${year}`;
        } catch (error) {
          console.error('Error formatting date for save:', dateString, error);
          return '';
        }
      };

      const [localAuth, setLocalAuth] = useState({
        id: auth.id || '',
        authorizationNumber: auth.AuthNum || '', // Changed from authorizationNumber
        status: auth.AuthStatus || '', // Changed from status
        startDate: formatDateForInput(auth.AuthStartDate) || '',
        endDate: formatDateForInput(auth.AuthEndDate) || '',
        sessionsApproved: auth.NumSessApproved || '', // Changed from sessionsApproved
        sessionNumber: auth.SessNumber || '', // Changed from sessionNumber
        authNotes: auth.AuthNotes || '' // Changed from authNotes
      });

      useEffect(() => {
        setLocalAuth({
          id: auth.id || '',
          authorizationNumber: auth.AuthNum || '',
          status: auth.AuthStatus || '',
          startDate: format(new Date(auth.AuthStartDate), 'yyyy-MM-dd') || '',
          endDate: format(new Date(auth.AuthEndDate), 'yyyy-MM-dd') || '',
          sessionsApproved: auth.NumSessApproved || '',
          sessionNumber: auth.SessNumber || '',
          authNotes: auth.AuthNotes || ''
        });
      }, [auth]);

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLocalAuth(prev => ({ ...prev, [name]: value}))
      };

      const handleSave = () => {
        // Convert dates back to MM/DD/YYYY format before saving
        const updatedAuth = {
          ...localAuth,
          AuthNum: localAuth.authorizationNumber,
          AuthStatus: localAuth.status,
          AuthStartDate: formatDateForSave(localAuth.startDate),
          AuthEndDate: formatDateForSave(localAuth.endDate),
          NumSessApproved: localAuth.sessionsApproved,
          SessNumber: localAuth.sessionNumber,
          AuthNotes: localAuth.authNotes
        };
        onUpdate(updatedAuth);
        setIsOpen(false);
      };

      const handleClose = () => {
        setIsOpen(false);
      }

      return (
        <>
          <div onClick={() => setIsOpen(true)} className="p-4 bg-white shadow rounded-lg cursor-pointer hover:shadow-md transition-shadow">
            <h4 className="font-bold mb-2 text-blue-500">Authorization {localAuth.id || '1'}</h4>
            <p> <span className='font-semibold'>Auth #: </span> {localAuth.authorizationNumber || 'N/A'}</p>
            <p><span className='font-semibold'>Sessions Approved: </span> {localAuth.sessionsApproved || 'N/A'}</p>
          </div>
          {isOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg w-full max-w-md max-h-[90vh] overflow-y-auto relative">
              <button 
                onClick={handleClose} 
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                aria-label="Close"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                </svg>
              </button>
                <h3 className="text-xl font-semibold mb-4">Edit Authorization</h3>
                <div className='mb-2'>
                  <label htmlFor="auth_num" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Authorization #</label>
                  <input name="authorizationNumber" type="text" id="auth_num" value={localAuth.authorizationNumber || ''} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="UM12345"  required />
                </div>
                <div className='mb-2'>
                    <label htmlFor="auth_status" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Authorization Status</label>
                    <div className="">
                            <select name="authStatus" value={localAuth.status} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="auth_status">
                              <option value="pending">Pending</option>
                              <option value="approved">Approved</option>
                              <option value="denied">Denied</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                              <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                    </div>
                  </div>
                <div className="mb-2">
                  <label htmlFor="authStart" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Authorization Start Date</label>
                  <input type="date" value={localAuth.startDate || ''} onChange={handleInputChange} name="authStart" id="authStart" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
                </div> 
                <div className="mb-2">
                  <label htmlFor="authEnd" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Authorization End Date</label>
                  <input type="date" value={localAuth.endDate || ''} onChange={handleInputChange} name="authEnd" id="authEnd" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
                </div> 
                <div className='mb-2'>
                  <label htmlFor="sessAppr" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Number of Sessions Approved</label>
                  <input name="sessionsApproved" type="text" id="sessAppr" value={localAuth.sessionsApproved || ''} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="10" required />
                </div>
                <div className='mb-2'>
                  <label htmlFor="sessNum" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Session Number</label>
                  <input name="sessionNumber" type="text" id="sessNum" value={localAuth.sessionNumber || ''} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="10" required />
                </div>
                <div className=''>
                  <label htmlFor="authNote" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Auth Notes</label>
                  <textarea name="authNotes" id="authNote" value={localAuth.authNotes} onChange={handleInputChange} rows="3" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Answer here if applicable..."></textarea>
                </div>
                <div className="flex justify-end mt-4 space-x-2">
                <button 
                onClick={handleClose}
                className="px-4 py-2 text-gray-600 bg-gray-100 rounded hover:bg-gray-200"
                >
                  Cancel
                </button>
                <button 
                  onClick={handleSave} 
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  Save
                </button>
                </div>
              </div>
            </div>
          )}
        </>
      );
    }

    const renderSection = (header, data, isInNetwork) => (
      <div className="">
        {/* <h2 className="text-pink-600 font-bold my-4">{header}</h2> */}
        <div className="grid gap-6 mb-6 md:grid-cols-3">
        <div className="">
          <label htmlFor="st_sess" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"><b className="text-red-500">ST</b> Sessions</label>
          <input
            type="number"
            id="st_sess"
            name="stSessions"
            value={data.Sessions || ''}
            onChange={(e) => handleInputChange(e, isInNetwork)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required
          />
        </div>
        <div>
          <form className="mx-auto">
              <label htmlFor="indiv_deduc_amt" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Individual Deductible Amount</label>
              <div className="">
                  <div className=" inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                  <svg className="-mb-10 w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 17.345a4.76 4.76 0 0 0 2.558 1.618c2.274.589 4.512-.446 4.999-2.31.487-1.866-1.273-3.9-3.546-4.49-2.273-.59-4.034-2.623-3.547-4.488.486-1.865 2.724-2.899 4.998-2.31.982.236 1.87.793 2.538 1.592m-3.879 12.171V21m0-18v2.2"/>
                  </svg>

                  </div>
                  <input name="individualDeductibleAmount" value={data.IndDeductAmt || ''} onChange={(e) => handleInputChange(e, isInNetwork)} type="number" id="indiv_deduc_amt" aria-describedby="helper-text-explanation" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="500" pattern="^\d{5}(-\d{4})?$" required />
              </div>
              <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">Please select a dollar amount..</p>
          </form>
        </div>
        <div>
          <form className="mx-auto">
              <label htmlFor="fam_deduc_amt" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Family Deductible Amount</label>
              <div className="">
                  <div className=" inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                  <svg className="-mb-10 w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 17.345a4.76 4.76 0 0 0 2.558 1.618c2.274.589 4.512-.446 4.999-2.31.487-1.866-1.273-3.9-3.546-4.49-2.273-.59-4.034-2.623-3.547-4.488.486-1.865 2.724-2.899 4.998-2.31.982.236 1.87.793 2.538 1.592m-3.879 12.171V21m0-18v2.2"/>
                  </svg>

                  </div>
                  <input name="familyDeductibleAmount" value={data.FamDeductAmt || ''} onChange={(e) => handleInputChange(e, isInNetwork)} type="number" id="fam_deduc_amt" aria-describedby="helper-text-explanation" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="500" pattern="^\d{5}(-\d{4})?$" required />
              </div>
              <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">Please select a dollar amount..</p>
          </form>
        </div>
        <div>
          <label htmlFor="applied_deduct" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Which Deductible Amt Applies To Claims</label>
          <div className="">
                  <select id="applied_deduct" name="appliedDeductible" value={data.WhichDeductApplies || ''} onChange={(e) => handleInputChange(e, isInNetwork)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  <option value={`Individual`}>Individual Deductible Amount - {data.IndDeductAmt}</option>
                  <option value={`Family`}>Family Deductible Amount - {data.FamDeductAmt}</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                  </div>
          </div>
        </div>
        <div>
          <label htmlFor="deduc_type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Deductible Type</label>
          <div className="">
                  <select name="deductibleType" value={data.DeductType || ''} onChange={(e) => handleInputChange(e, isInNetwork)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="grid-state">
                  <option value="Combined">Combined</option>
                  <option value="Individual">Individual</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                  </div>
          </div>
        </div>
        <div className="">
          <form className="mx-auto">
              <label htmlFor="charge_till_deduc" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Charge Per Session Until Deductible Met</label>
              <div className="">
                  <div className=" inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                  <svg className="-mb-10 w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 17.345a4.76 4.76 0 0 0 2.558 1.618c2.274.589 4.512-.446 4.999-2.31.487-1.866-1.273-3.9-3.546-4.49-2.273-.59-4.034-2.623-3.547-4.488.486-1.865 2.724-2.899 4.998-2.31.982.236 1.87.793 2.538 1.592m-3.879 12.171V21m0-18v2.2"/>
                  </svg>

                  </div>
                  <input name="chargePerSession" value={data.ChargePerSess || ''} onChange={(e) => handleInputChange(e, isInNetwork)} type="number" id="charge_till_deduc" aria-describedby="helper-text-explanation" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="85" pattern="^\d{5}(-\d{4})?$" required />
              </div>
              <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">Please select a dollar amount..</p>
          </form>
        </div>
        <div>
          <form className="mx-auto">
              <label htmlFor="copay_amt" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Copay Amount</label>
              <div className="">
                  <div className=" inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                  <svg className="-mb-10 w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 17.345a4.76 4.76 0 0 0 2.558 1.618c2.274.589 4.512-.446 4.999-2.31.487-1.866-1.273-3.9-3.546-4.49-2.273-.59-4.034-2.623-3.547-4.488.486-1.865 2.724-2.899 4.998-2.31.982.236 1.87.793 2.538 1.592m-3.879 12.171V21m0-18v2.2"/>
                  </svg>

                  </div>
                  <input name="copayAmount" value={data.CopayAmt || ''} onChange={(e) => handleInputChange(e, isInNetwork)} type="number" id="copay_amt" aria-describedby="helper-text-explanation" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="10" pattern="^\d{5}(-\d{4})?$" required />
              </div>
              <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">Please select a dollar amount..</p>
          </form>
          </div>
         <div>
            <label htmlFor="auth_req" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Authorization Required</label>
            <div className="">
                  <select name="authRequired" value={data.AuthRequired || ''} onChange={(e) => handleInputChange(e, isInNetwork)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="grid-state">
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                  </div>
          </div>
          </div>
          <div>
            <label htmlFor="hard_max" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Hard Max</label>
            <div className="">
                    <select name="hardMax" value={data.HardMax || ''} onChange={(e) => handleInputChange(e, isInNetwork)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="grid-state">
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
            </div>
          </div>
          <div>
            <label htmlFor="checks_to" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Checks to Member</label>
            <div className="">
                    <select name="checksToMember" value={data.ChecksToMember || ''} onChange={(e) => handleInputChange(e, isInNetwork)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="relation">
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
            </div>
          </div>
          <div className="">
            <label htmlFor="develDelay" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Is Developmental Delay Covered?</label>
            <input
              type="text" id="develDelay" name="develDelay"
              value={data.DevDelayCov || ''}
              onChange={(e) => handleInputChange(e, isInNetwork)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required
            />
          </div>
          <div className="">
            <label htmlFor="autismCov" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Is Autism Covered (And If So, Do The Benefits Change)?</label>
            <input
              type="text" id="autismCov" name="autismCov"
              value={data.AutismCov || ''}
              onChange={(e) => handleInputChange(e, isInNetwork)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required
            />
          </div>
        </div>
        <div className="col-span-3">
            <label htmlFor="authExclusions" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Are There Any Exclusions (Rehabilitative Care or Habilitative Care)?</label>
            <input
              type="text" id="authExclusions" name="authExclusions"
              value={data.Exclusions || ''}
              onChange={(e) => handleInputChange(e, isInNetwork)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required
            />
          </div>
        <div className="mt-6">
          <h3 className='text-lg font-semibold mb-4'>Authorizations</h3>
          <div className='grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3'>
            {data.authorizations && data.authorizations.length > 0 ? (
              data.authorizations.map((auth, index) => (
                <AuthorizationCard 
                  key={index} 
                  auth={auth} 
                  onUpdate={(updatedAuth) => handleAuthUpdate(index, updatedAuth, isInNetwork)} />
              ))
            ) : (
              <AuthorizationCard auth={{}} onUpdate={(newAuth) => handleAuthUpdate(0, newAuth, isInNetwork)} />
            )}
            <button onClick={(e) => handleAddAuthorization(isInNetwork, e)} className="flex items-center justify-center h-40 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors">
              <svg className='w-8 h-8 text-gray-400' fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
              </svg>
            </button>
          </div>
        </div>
        <div className='mt-4'>
            <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Notes</label>
            <textarea name="notes" value={data.AuthNotes} onChange={(e) => handleInputChange(e, isInNetwork)} id="message" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Answer here if applicable..."></textarea>
          </div>
      </div>
    );

    if (!patient) {
      return <div>Loading patient data...</div>;
    }

    return (
        <div className=''>
        <form>
        <div className="grid gap-6 mb-6 md:grid-cols-4">
            <div>
                <label htmlFor="child_first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Child First Name</label>
                <input type="text" id="child_first_name" value={firstName} onChange={(e) => setFirstName(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder='test' required/>
            </div>
            <div>
                <label htmlFor="child_last_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Child Last Name</label>
                <input type="text" id="child_last_name" value={lastName} onChange={(e) => setLastName(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="test" required />
            </div>
            <div>
                <label htmlFor="child_dob" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Child DOB</label>
                <input type="date" id="child_dob" value={dob} onChange={(e) => setDob(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div>  
            <div>
                <label htmlFor="insur" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Insurance</label>
                <input type="text" id="insur" value={insuranceCompany} onChange={(e) => setInsuranceCompany(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="123-45-678" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required />
            </div>
            <div>
                <label htmlFor="child_mem_id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Child Member ID #</label>
                <input type="text" id="child_mem_id" value={childMemIdNum} onChange={(e) => setChildMemIdNum(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div>
            <div>
                <label htmlFor="insur_comp_id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Insurance Company #</label>
                <input type="text" id="insur_comp_id" value={insurCompNum} onChange={(e) => setInsurCompNum(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div>
            <div>
                <label htmlFor="sub_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Subscriber Name</label>
                <input type="text" id="sub_name" value={subscriberName} onChange={(e) => setSubscriberName(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div>
            <div>
                <label htmlFor="sub_dob" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Subscriber DOB</label>
                <input type="text" id="sub_dob" value={subscriberDob} onChange={(e) => setSubscriberDob(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div>
            <div>
                <label htmlFor="relation" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Relationship to Dependent</label>
                <div className="">
                        <select value={subscriberRelationship} onChange={(e) => setSubscriberRelationship(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="relation">
                        <option>Mother</option>
                        <option>Father</option>
                        <option>Grandmother</option>
                        <option>Grandfather</option>
                        <option>Sister</option>
                        <option>Brother</option>
                        <option>Aunt</option>
                        <option>Uncle</option>
                        <option>Step-parent</option>
                        <option>Other</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                </div>
            </div>
            <div>
                <label htmlFor="sub_mem_id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Subscriber Member ID #</label>
                <input type="text" id="sub_mem_id" value={subscriberMemIdNum} onChange={(e) => setSubscriberMemIdNum(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div>
        </div>
        <hr className="mt-8 h-0.5 border-t-0 bg-gray-700"/>
        <div className=''>
          {renderSectionHeader("In Network", isFirstSectionOpen, setIsFirstSectionOpen, 'in')}
      {isFirstSectionOpen && renderSection("In Network", inNetworkData, true)}

      {renderSectionHeader("Out of Network", isSecondSectionOpen, setIsSecondSectionOpen, 'out')}
      {isSecondSectionOpen && renderSection("Out of Network", outOfNetworkData, false)}
        </div>
        <hr className="my-12 h-0.5 border-t-0 bg-gray-700"/>
        
        <div className="grid gap-6 mb-6 md:grid-cols-1">
        <div className="mt-6 mb-2">
                <label htmlFor="effect_date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Policy Effective Date</label>
                <input type="date" id="effect_date" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div> 
            <div className="mb-2">
                <label htmlFor="terminate_date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Policy Termination Date</label>
                <input type="date" id="terminate_date" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div> 
            <div className="mb-6">
                <label htmlFor="renew_date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">When Does Deductible Renew</label>
                <input type="date" id="renew_date" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div> 
  
        </div>
        <hr className="mb-6 h-0.5 border-t-0 bg-gray-700"/>
        

        <div className="overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Speech
                        </th>
                        <th scope="col" className="px-6 py-3">
                            <div className="flex items-center">
                                Yes
                                <a href=""><svg className="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
            <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z"/>
          </svg></a>
                            </div>
                        </th>
                        <th scope="col" className="px-6 py-3">
                            <div className="flex items-center">
                                No
                                <a href=""><svg className="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
            <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z"/>
          </svg></a>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            92507
                        </th>
                        <td className="px-6 py-4">
                          <input 
                          type="checkbox"
                          checked={rowValues['92507'] === 'Yes'}
                          onChange={() => handleCheckboxChange('92507', 'yes')}
                          />
                        </td>
                        <td className="px-6 py-4">
                        <input 
                          type="checkbox"
                          checked={rowValues['92507'] === 'No'}
                          onChange={() => handleCheckboxChange('92507', 'no')}
                          />
                        </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            97535
                        </th>
                        <td className="px-6 py-4">
                        <input 
                          type="checkbox"
                          checked={rowValues['97535'] === 'Yes'}
                          onChange={() => handleCheckboxChange('97535', 'yes')}
                          />
                        </td>
                        <td className="px-6 py-4">
                        <input 
                          type="checkbox"
                          checked={rowValues['97535'] === 'No'}
                          onChange={() => handleCheckboxChange('97535', 'no')}
                          />
                        </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            92526
                        </th>
                        <td className="px-6 py-4">
                        <input 
                          type="checkbox"
                          checked={rowValues['92526'] === 'Yes'}
                          onChange={() => handleCheckboxChange('92526', 'yes')}
                          />
                        </td>
                        <td className="px-6 py-4">
                          <input 
                          type="checkbox"
                          checked={rowValues['92526'] === 'No'}
                          onChange={() => handleCheckboxChange('92526', 'no')}
                          />
                        </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Speech Eval
                        </th>
                        <td className="px-6 py-4">
                        <input 
                          type="checkbox"
                          checked={rowValues['Speech Eval'] === 'Yes'}
                          onChange={() => handleCheckboxChange('Speech Eval', 'yes')}
                          />
                        </td>
                        <td className="px-6 py-4">
                        <input 
                          type="checkbox"
                          checked={rowValues['Speech Eval'] === 'No'}
                          onChange={() => handleCheckboxChange('Speech Eval', 'no')}
                          />
                        </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            99213
                        </th>
                        <td className="px-6 py-4">
                        <input 
                          type="checkbox"
                          checked={rowValues['99213'] === 'Yes'}
                          onChange={() => handleCheckboxChange('99213', 'yes')}
                          />
                        </td>
                        <td className="px-6 py-4">
                        <input 
                          type="checkbox"
                          checked={rowValues['99213'] === 'No'}
                          onChange={() => handleCheckboxChange('99213', 'no')}
                          />
                        </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            92610
                        </th>
                        <td className="px-6 py-4">
                        <input 
                          type="checkbox"
                          checked={rowValues['92610'] === 'Yes'}
                          onChange={() => handleCheckboxChange('92610', 'yes')}
                          />
                        </td>
                        <td className="px-6 py-4">
                        <input 
                          type="checkbox"
                          checked={rowValues['92610'] === 'No'}
                          onChange={() => handleCheckboxChange('92610', 'no')}
                          />
                        </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            92523
                        </th>
                        <td className="px-6 py-4">
                        <input 
                          type="checkbox"
                          checked={rowValues['92523'] === 'Yes'}
                          onChange={() => handleCheckboxChange('92523', 'yes')}
                          />
                        </td>
                        <td className="px-6 py-4">
                        <input 
                          type="checkbox"
                          checked={rowValues['92523'] === 'No'}
                          onChange={() => handleCheckboxChange('92523', 'no')}
                          />
                        </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            99366
                        </th>
                        <td className="px-6 py-4">
                        <input 
                          type="checkbox"
                          checked={rowValues['99366'] === 'Yes'}
                          onChange={() => handleCheckboxChange('99366', 'yes')}
                          />
                        </td>
                        <td className="px-6 py-4">
                        <input 
                          type="checkbox"
                          checked={rowValues['99366'] === 'No'}
                          onChange={() => handleCheckboxChange('99366', 'no')}
                          />
                        </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            92524
                        </th>
                        <td className="px-6 py-4">
                        <input 
                          type="checkbox"
                          checked={rowValues['92524'] === 'Yes'}
                          onChange={() => handleCheckboxChange('92524', 'yes')}
                          />
                        </td>
                        <td className="px-6 py-4">
                        <input 
                          type="checkbox"
                          checked={rowValues['92524'] === 'No'}
                          onChange={() => handleCheckboxChange('92524', 'no')}
                          />
                        </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Only for Intake 92521 and 99203
                        </th>
                        <td className="px-6 py-4">
                        <input 
                          type="checkbox"
                          checked={rowValues['Only for Intake 92521 and 99203'] === 'Yes'}
                          onChange={() => handleCheckboxChange('Only for Intake 92521 and 99203', 'yes')}
                          />
                        </td>
                        <td className="px-6 py-4">
                        <input 
                          type="checkbox"
                          checked={rowValues['Only for Intake 92521 and 99203'] === 'No'}
                          onChange={() => handleCheckboxChange('Only for Intake 92521 and 99203', 'no')}
                          />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <form onSubmit={handleSubmit}>
          <button type="submit" className="mt-6 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
        </form>
        </form>
    </div>
    );
};

export default STPayInsuranceTab;