import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { format, parse } from 'date-fns';
import Header from './Header';
import { ArrowLeftIcon, BookmarkSquareIcon} from '@heroicons/react/24/solid';

const ModifyEvent = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const eventPassed = location.state?.event;
  const page = location.state?.page;
  const [event, setEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if(eventPassed) {
        setEvent({
            ...eventPassed,
            date: format(new Date(eventPassed.start_time), 'yyyy-MM-dd'),
            startTime: format(new Date(eventPassed.start_time), 'HH:mm'),
            endTime: format(new Date(eventPassed.end_time), 'HH:mm'),
        });
    }
    setIsLoading(false);
  }, [eventPassed]);

  const getReturnLink = (page) => {
    switch (page) {
      case 'HomePage':
        return '/home';
      case 'WeekView':
        return '/weekview';
    case 'Calendar':
        return '/calendar';
      // Add more cases as necessary
      default:
        return '/';  // Fallback to home or a default route
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEvent(prev => ({...prev, [name]: value }));
  };

  const handleReturn = () => {
    const returnLink = getReturnLink(page);
    navigate(returnLink);
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/delete_event', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          event_id: event.event_id
        })
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete event');
      }
  
      const data = await response.json();
      console.log("Successfully deleted event:", data);
      
      // Close the modal and navigate back
      setShowConfirmDelete(false);
      const returnLink = getReturnLink(page);
      navigate(returnLink);
    } catch (error) {
      console.error('Error deleting event:', error);
      alert('Failed to delete event. Please try again.');
      setShowConfirmDelete(false);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDeleteClick = () => {
    setShowConfirmDelete(true);
  }

  const handleCancelDelete = () => {
    setShowConfirmDelete(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the updated event data to your backend
    console.log('Updated event:', event);
    // For now, we'll just navigate back to the homepage
    alert('Updated event:', event);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!event) {
    return <div>Event not found</div>;
  }

  return (
    <div>
        <Header />
        <div className="container mx-auto p-4 mt-20">
        <h1 className="text-2xl font-bold mb-4">Modify Event</h1>
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="event_name">
                Event Name
            </label>
            <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="event_name"
                type="text"
                name="event_name"
                value={event.event_name}
                onChange={handleInputChange}
            />
            </div>
            <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="date">
                Date
            </label>
            <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="date"
                type="date"
                name="date"
                value={event.date}
                onChange={handleInputChange}
            />
            </div>
            <div className="flex mb-4">
            <div className="w-1/2 mr-2">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="startTime">
                Start Time
                </label>
                <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="startTime"
                type="time"
                name="startTime"
                value={event.startTime}
                onChange={handleInputChange}
                step={60}
                />
            </div>
            <div className="w-1/2 ml-2">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="endTime">
                End Time
                </label>
                <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="endTime"
                type="time"
                name="endTime"
                value={event.endTime}
                onChange={handleInputChange}
                />
            </div>
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="ev_location">
                    Location
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="ev_location"
                    type="text"
                    name="ev_location"
                    value={event.ev_location}
                    onChange={handleInputChange}
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="ev_status">
                    Status
                </label>
                <select
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="ev_status"
                    name="ev_status"
                    value={
                    event.ev_status === 'A' ? 'Scheduled' :
                    event.ev_status === 'Y' ? 'Confirmed' :
                    event.ev_status === 'N' ? 'No Show' :
                    event.ev_status === 'C' ? (event.cancel_reason || 'Canceled') : ''
                    }
                    onChange={handleInputChange}
                >
                    <option value="Scheduled">Scheduled</option>
                    <option value="Confirmed">Confirmed</option>
                    <option value="No Show">No Show</option>
                    <option value="Canceled by Patient">Canceled by Patient</option>
                    <option value="Canceled by Provider">Canceled by Provider</option>
                </select>
            </div>
            <div className="mb-4">
                <button 
                    type="button"
                    onClick={handleDeleteClick}
                    className="w-full h-10 text-white font-semibold bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-700 rounded-md shadow-lg hover:shadow-xl transition-all duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-red-400"
                >
                    Delete All Future Occurrences Of This Appointment
                </button>
            </div>

            {/* Popup modal */}
            {showConfirmDelete && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                <div className="bg-white p-6 rounded-md shadow-md w-80">
                    <h2 className="text-xl font-semibold text-gray-800 mb-4">Are you sure?</h2>
                    <p className="text-sm text-gray-600 mb-6">
                    This action cannot be undone and the event will have to be recreated.
                    </p>
                    <div className="flex justify-between">
                    <button
                        onClick={handleDelete}
                        className="w-1/2 h-10 text-white font-semibold bg-red-600 hover:bg-red-700 rounded-md shadow-md transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-400 mr-2"
                    >
                        Yes, delete
                    </button>
                    <button
                        onClick={handleCancelDelete}
                        className="w-1/2 h-10 text-gray-700 font-semibold bg-gray-200 hover:bg-gray-300 rounded-md shadow-md transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-300 ml-2"
                    >
                        No, return
                    </button>
                    </div>
                </div>
                </div>
            )}

            <div className="flex items-center justify-between">
            <button 
                onClick={handleSubmit}
                type="submit"
                className="group relative inline-flex items-center justify-center overflow-hidden rounded-lg bg-gradient-to-br from-slate-500 to-slate-700 p-0.5 text-sm font-medium text-white hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300 group-hover:from-blue-600 group-hover:to-blue-800"
            >
                <span className="relative flex items-center rounded-md bg-white px-3 py-2.5 transition-all duration-75 ease-in group-hover:bg-opacity-0">
                <BookmarkSquareIcon className="h-5 w-5 mr-2 text-blue-600 group-hover:text-white" />
                <span className="text-blue-600 group-hover:text-white">Save Changes</span>
                </span>
            </button>
            <button 
                onClick={handleReturn}
                type="button"
                className="group relative inline-flex items-center justify-center overflow-hidden rounded-lg bg-gradient-to-br from-slate-500 to-slate-700 p-0.5 text-sm font-medium text-white hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300 group-hover:from-blue-600 group-hover:to-blue-800"
            >
                <span className="relative flex items-center rounded-md bg-white px-3 py-2.5 transition-all duration-75 ease-in group-hover:bg-opacity-0">
                <ArrowLeftIcon className="h-5 w-5 mr-2 text-blue-600 group-hover:text-white" />
                <span className="text-blue-600 group-hover:text-white">Return to {page}</span>
                </span>
            </button>
            </div>
        </form>
        </div>
    </div>
  );
};

export default ModifyEvent;