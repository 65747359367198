import React from 'react';
import OTInsuranceTable from './OTInsuranceTable';

const OTInsurance = ({ patient }) => {
  return (
    <div>
      <OTInsuranceTable patient={patient}/>
    </div>
  );
};

export default OTInsurance;