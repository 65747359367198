// UserContext.js
import { createContext, useContext, useState, useEffect } from 'react';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userName, setUserName] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [isSessionValid, setIsSessionValid] = useState(false);
  const [userId, setUserId] = useState(null);

  // Function to fetch user info and map ID to name
  const fetchUserInfo = async (userId) => {
    const apiUrl = 'https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/find_login_user';
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: userId }),
      });
      const data = await response.json();
      const bodyData = typeof data.body === 'string' ? JSON.parse(data.body) : data.body;
      return bodyData;
    } catch (error) {
      console.error('Error fetching user info:', error);
      return null;
    }
  };

  useEffect(() => {
    const checkSession = async () => {
      try {
        const currentUser = await getCurrentUser();
        const { tokens } = await fetchAuthSession();
        
        if (tokens && currentUser) {
          setUserId(currentUser.username);
          setIsSessionValid(true);

          // If we already have a userName, don't fetch again
          if (!userName && currentUser.username) {
            const userInfo = await fetchUserInfo(currentUser.username);
            if (userInfo?.name) {
              setUserName(userInfo.name);
              setUserRole(userInfo.role || '');
              
              // Store in sessionStorage as backup
              sessionStorage.setItem('userName', userInfo.name);
              sessionStorage.setItem('userRole', userInfo.role || '');
            }
          }
        }
      } catch (error) {
        console.error('Error checking session:', error);
        handleSignOut();
      }
    };

    checkSession();

    // Set up interval to check session
    const interval = setInterval(checkSession, 5000); // Check every 5 seconds
    return () => clearInterval(interval);
  }, [userName]);

  // Restore from sessionStorage if needed
  useEffect(() => {
    if (!userName) {
      const storedName = sessionStorage.getItem('userName');
      const storedRole = sessionStorage.getItem('userRole');
      if (storedName) {
        setUserName(storedName);
        setUserRole(storedRole);
      }
    }
  }, [userName]);

  const handleSignOut = async () => {
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('userRole');
    setUserName(null);
    setUserRole(null);
    setIsSessionValid(false);
    setUserId(null);
  };

  return (
    <UserContext.Provider 
      value={{ 
        userName, 
        setUserName, 
        userRole, 
        setUserRole,
        isSessionValid,
        handleSignOut,
        userId
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};