// AuthGuard.js
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from './UserContext';
import { fetchAuthSession } from 'aws-amplify/auth';

export const AuthGuard = ({ children }) => {
  const [isChecking, setIsChecking] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const { userName } = useUser();
  const location = useLocation();

  useEffect(() => {
    const checkSession = async () => {
      try {
        const { tokens } = await fetchAuthSession();
        setIsValid(!!tokens && !!userName);
      } catch (error) {
        console.error('Session check failed:', error);
        setIsValid(false);
      } finally {
        setIsChecking(false);
      }
    };

    checkSession();
  }, [userName]);

  if (isChecking) {
    return null;
  }

  if (!isValid) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};