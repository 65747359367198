import React, { useEffect, useState, useRef } from 'react';
import { Bars3BottomRightIcon, XMarkIcon, ChevronDownIcon, UserIcon, LinkIcon } from '@heroicons/react/24/solid'
import { Authenticator } from '@aws-amplify/ui-react';
import { HomeIcon, UsersIcon, FolderIcon, CalendarIcon, PencilSquareIcon, CreditCardIcon, ChartBarIcon, BellAlertIcon, ChatBubbleLeftIcon, ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { useUser } from './UserContext';
import { Link } from 'react-router-dom';

const Header = () => {
    const { userName, userRole, userId } = useUser();
    const [open, setOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = React.useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if click is outside both the button and the dropdown menu
            if (
                dropdownOpen && 
                !event.target.closest('.user-dropdown-button') && 
                !event.target.closest('.user-dropdown-menu')
            ) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [dropdownOpen]);

    let Links =[
        { name: 'Dashboard', link: '/Home', icon: HomeIcon },
        { name: 'Alerts', link: '/Alerts', icon: BellAlertIcon},
        { name: 'Patients', link: '/Patients', icon: UsersIcon},
        //{ name: 'Chat', link: '/Chat', icon: ChatBubbleLeftIcon},
        { name: 'Calendar', link: '/Calendar', icon: CalendarIcon},
        { name: 'HR', link: '/HR', icon: ChartBarIcon},
        { name: 'Forms', link: '/Forms', icon: ClipboardDocumentIcon},
        { name: 'Charge', link: '/chargingtable', icon: CreditCardIcon},
      ];

    const displayName = userName || 'Loading...';

    return (
        <div className='shadow-md w-full fixed top-0 left-0'>
           <div className='md:flex items-center justify-between py-4 md:px-10 px-7 bg-blue-800'>
            {/* logo section */}
            <Link to="/Home" className='font-bold text-2xl text-white flex items-center gap-1 hover:text-blue-400 duration-500'>
                <img src="/sensorylogo.png" className="w-20 h-13" alt="MaxStudioHealth Logo" />
                <span>MaxStudioHealth</span>
            </Link>
            {/* Menu icon */}
            <div onClick={()=>setOpen(!open)} className='absolute right-8 top-6 cursor-pointer md:hidden w-7 h-7'>
                {open ? <XMarkIcon/> : <Bars3BottomRightIcon />}
            </div>

            {/* linke items */}
            <ul className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-blue-800 md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-12' : 'top-[-490px]'}`}>
                {Links.map((link) => (
                    <li className='md:ml-8 md:my-0 my-7 font-semibold' key={link.name}>
                        <Link to={link.link} className='flex text-white hover:text-blue-400 duration-500'>
                            {link.icon && <link.icon className="h-5 w-5 mr-1" />}
                            {link.name}
                        </Link>
                    </li>
                ))}
                {/* User dropdown */}
                <Authenticator>
                    {({ signOut }) => (
                        <div className="relative md:ml-8">
                            <button 
                                onClick={() => setDropdownOpen(!dropdownOpen)}
                                className="flex items-center gap-2 px-4 py-2 text-white hover:text-blue-400 duration-500 font-semibold user-dropdown-button"
                            >
                                {displayName}
                                <ChevronDownIcon className="h-4 w-4" />
                            </button>
                            
                            {dropdownOpen && (
                                <div ref={dropdownRef} className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 user-dropdown-menu">
                                    <div className="py-1">
                                        <Link 
                                            to='/Reports'
                                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                        >
                                            Reports
                                        </Link>
                                    </div>
                                    <div className="py-1">
                                        <button
                                            onClick={signOut}
                                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                        >
                                            Sign out
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </Authenticator>
            </ul>
            {/* button */}
           </div>
        </div>
    );
};

export default Header;