import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Check } from 'lucide-react';

const AddPatientPopUp = ({ openPopUp, closePopUp, fetchPatients, autoPatientID, patients }) => {
  const [newPatient, setNewPatient] = useState({
    PatientID: '',
    LastFirst: '',
    InactivePt: '',
    Service: '',
    LastName: '',
    FirstName: '',
    PhoneNumber: '',
    Email: '',
    ParentGuardian: '',
    InsuranceCompany: '',
  });

  const [currentStep, setCurrentStep] = useState(1);
  
  useEffect(() => {
    if (openPopUp) {
      const maxPatientID = getMaxPatientID(patients);
      const newPatientID = (maxPatientID + 1).toString();
      setNewPatient({ ...newPatient, PatientID: newPatientID });
    }
  }, [openPopUp, patients]);

  const getMaxPatientID = (patients) => {
    if (patients.length === 0) return 0;
    const patientIDs = patients.map((patient) => parseInt(patient.PatientID));
    return Math.max(...patientIDs);
  };

  const handleAddPatient = async () => {
    const updatedNewPatient = {
      ...newPatient,
      PatientID: autoPatientID,
    };

    try {
      const response = await axios.post(
        'https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/addPatient',
        null,
        {
          headers: {
            "Content-Type": "application/json",
            ...updatedNewPatient
          },
        }
      );

      if (response.data.statusCode === 200) {
        alert('Patient Added Successfully!');
        resetForm();
      } else {
        alert('Failed to add patient.');
      }
    } catch (error) {
      console.error('Error adding patient:', error);
      alert('Error adding patient. Please try again.');
    }
  };

  const resetForm = () => {
    setNewPatient({
      PatientID: '',
      LastFirst: '',
      InactivePt: '',
      Service: '',
      LastName: '',
      FirstName: '',
      PhoneNumber: '',
      Email: '',
      ParentGuardian: '',
      InsuranceCompany: '',
    });
    setCurrentStep(1);
    closePopUp();
    fetchPatients();
  };

  const handleClosePopUp = (e) => {
    if (e.target.id === 'ModelContainer') {
      closePopUp();
    }
  };

  if (!openPopUp) return null;

  const steps = [
    { id: 1, title: 'Personal Details' },
    { id: 2, title: 'Contact Information' },
    { id: 3, title: 'Service Details' }
  ];

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <Input
                label="First Name"
                id="FirstName"
                value={newPatient.FirstName}
                onChange={(e) => setNewPatient({ ...newPatient, FirstName: e.target.value })}
                placeholder="John"
                required
              />
              <Input
                label="Last Name"
                id="LastName"
                value={newPatient.LastName}
                onChange={(e) => setNewPatient({ ...newPatient, LastName: e.target.value })}
                placeholder="Doe"
                required
              />
              <Input
                label="Patient ID"
                id="PatientID"
                value={newPatient.PatientID}
                disabled
                className="bg-gray-50"
              />
              <Input
                label="Last First"
                id="LastFirst"
                value={newPatient.LastFirst}
                onChange={(e) => setNewPatient({ ...newPatient, LastFirst: e.target.value })}
                placeholder="DoeJohn"
                required
              />
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="grid grid-cols-1 gap-6">
              <Input
                label="Primary Contact"
                id="ParentGuardian"
                value={newPatient.ParentGuardian}
                onChange={(e) => setNewPatient({ ...newPatient, ParentGuardian: e.target.value })}
                placeholder="Jane Doe"
                required
              />
              <Input
                label="Phone Number"
                id="PhoneNumber"
                value={newPatient.PhoneNumber}
                onChange={(e) => setNewPatient({ ...newPatient, PhoneNumber: e.target.value })}
                placeholder="123-456-7890"
                type="tel"
                required
              />
              <Input
                label="Email"
                id="Email"
                value={newPatient.Email}
                onChange={(e) => setNewPatient({ ...newPatient, Email: e.target.value })}
                placeholder="john.doe@gmail.com"
                type="email"
                required
              />
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="grid grid-cols-1 gap-6">
              <Select
                label="Status"
                id="InactivePt"
                value={newPatient.InactivePt}
                onChange={(e) => setNewPatient({ ...newPatient, InactivePt: e.target.value })}
                options={['Pending', 'New', 'Active', 'Inactive', 'Waiting List']}
                required
              />
              <Select
                label="Service(s)"
                id="Service"
                value={newPatient.Service}
                onChange={(e) => setNewPatient({ ...newPatient, Service: e.target.value })}
                options={['Speech Therapy', 'Occupational Therapy', 'Peer Group', 'S.T. and O.T.', 'S.T. and P.G.', 'O.T. and P.G.']}
                required
              />
              <Input
                label="Insurance Company"
                id="InsuranceCompany"
                value={newPatient.InsuranceCompany}
                onChange={(e) => setNewPatient({ ...newPatient, InsuranceCompany: e.target.value })}
                required
              />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div
      id="ModelContainer"
      onClick={handleClosePopUp}
      className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center backdrop-blur-sm"
    >
      <div className="bg-white w-11/12 md:w-2/3 lg:w-1/2 xl:w-2/5 rounded-2xl shadow-xl">
        <div className="p-8">
          <h2 className="text-2xl font-semibold mb-6">Add Patient</h2>
          
          {/* Progress Steps */}
          <div className="mb-8">
            <div className="flex items-center justify-between relative">
              {steps.map((step, index) => (
                <div key={step.id} className="flex flex-col items-center relative z-10">
                  <div className={`w-8 h-8 rounded-full flex items-center justify-center border-2 
                    ${currentStep >= step.id ? 'bg-blue-500 border-blue-500 text-white' : 'border-gray-300 text-gray-300'}`}>
                    {currentStep > step.id ? <Check className="w-5 h-5" /> : step.id}
                  </div>
                  <span className={`mt-2 text-sm ${currentStep >= step.id ? 'text-blue-500' : 'text-gray-400'}`}>
                    {step.title}
                  </span>
                </div>
              ))}
              {/* Progress Line */}
              <div className="absolute top-4 left-0 w-full h-0.5 bg-gray-200 -z-10">
                <div 
                  className="absolute top-0 left-0 h-full bg-blue-500 transition-all duration-300"
                  style={{ width: `${((currentStep - 1) / (steps.length - 1)) * 100}%` }}
                />
              </div>
            </div>
          </div>

          {/* Form Content */}
          <div className="mb-8">
            {renderStepContent()}
          </div>

          {/* Navigation Buttons */}
          <div className="flex justify-between mt-8">
            <button
              onClick={() => setCurrentStep(curr => curr - 1)}
              className={`px-6 py-2 rounded-lg text-gray-600 hover:bg-gray-100 transition-colors
                ${currentStep === 1 ? 'invisible' : ''}`}
            >
              Previous
            </button>
            <button
              onClick={() => {
                if (currentStep === steps.length) {
                  handleAddPatient();
                } else {
                  setCurrentStep(curr => curr + 1);
                }
              }}
              className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              {currentStep === steps.length ? 'Submit' : 'Next'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Input Component
const Input = ({ label, id, className = '', ...props }) => (
  <div className="relative">
    <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
      {label}
    </label>
    <input
      id={id}
      className={`w-full px-4 py-2.5 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors ${className}`}
      {...props}
    />
  </div>
);

// Select Component
const Select = ({ label, id, options, ...props }) => (
  <div className="relative">
    <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
      {label}
    </label>
    <select
      id={id}
      className="w-full px-4 py-2.5 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors appearance-none bg-white"
      {...props}
    >
      <option value="">Select {label}</option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  </div>
);

export default AddPatientPopUp;