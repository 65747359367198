import React, { useState, useEffect } from 'react';
import STPayInsuranceTab from './STPayInsuranceTab';
import STPayOopTab from './STPayOopTab';
import { InfoIcon } from 'lucide-react';

const STInsuranceTable = ({ patient }) => {
  const [primary, setPrimary] = useState('neither');
  const [activeTab, setActiveTab] = useState('payinsurance');
  const [verificationStatus, setVerificationStatus] = useState('');
  const [patientBillingInfo, setPatientBillingInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchBillingInfo = async () => {
      try {
        const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/find_patient_billing_info', {
          headers: {
            'PatientID': patient.PatientID
          }
        });
        
        const data = await response.json();
        if (data.statusCode === 200) {
          console.log("Billing Data Body:", data.body)
          const billingInfo = JSON.parse(data.body)[0]; // Parse the stringified body
          setPatientBillingInfo(billingInfo);
          
          // Set primary billing method
          if (billingInfo?.ST?.PrimaryMethod) {
            const method = billingInfo.ST.PrimaryMethod.toLowerCase();
            if (method === 'insurance') {
              setPrimary('payinsurance');
              setActiveTab('payinsurance');
            } else if (method === 'out of pocket') {
              setPrimary('payoop');
              setActiveTab('payoop');
            } else {
              setPrimary('neither');
            }
          } else {
            setPrimary('neither');
          }

          // Set verification status
          if (billingInfo?.InsuranceVerificationStatus) {
            switch (billingInfo.InsuranceVerificationStatus) {
              case 'Alert Billing':
                setVerificationStatus('alertBilling');
                break;
              case 'Alert PC':
                setVerificationStatus('alertPC');
                break;
              case 'Reported Benefits':
                setVerificationStatus('reportedBenefits');
                break;
              default:
                setVerificationStatus('');
            }
          }
        }
      } catch (error) {
        console.error('Error fetching billing info:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (patient?.PatientID) {
      fetchBillingInfo();
    }
  }, [patient?.PatientID]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handlePrimaryMethodChange = (e) => {
    setPrimary(e.target.value);
  };

  // Update the handler for verification status radio buttons
  const handleVerificationStatusChange = (event) => {
    setVerificationStatus(event.target.value);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'payinsurance':
        return <STPayInsuranceTab patient={patient} patientBillingInfo={patientBillingInfo}/>;
      case 'payoop':
        return <STPayOopTab patient={patient} patientBillingInfo={patientBillingInfo}/>;
      default:
        return null;
    }
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-full">Loading...</div>;
  }

  return (
    <div className="bg-white rounded-lg shadow-md h-full">
      <div className="flex gap-24 mb-8">
        {/* First Radio Group */}
        <div className="flex flex-col w-1/3 ml-2">
          <h3 className="mb-2 font-semibold text-gray-900 dark:text-white">
            Primary Billing Method for Speech Therapy:
          </h3>
          <ul className="w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
              <div className="flex items-center justify-center h-full px-4 py-2">
                <div className="flex items-center gap-2">
                  <input
                    onChange={handlePrimaryMethodChange}
                    checked={primary === 'payinsurance'}
                    id="horizontal-list-radio-insurance"
                    type="radio"
                    value="payinsurance"
                    name="list-radio"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    htmlFor="horizontal-list-radio-insurance"
                    className="py-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Insurance
                  </label>
                </div>
              </div>
            </li>
            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
              <div className="flex items-center justify-center h-full px-4 py-2">
                <div className="flex items-center gap-2">
                  <input
                    onChange={handlePrimaryMethodChange}
                    checked={primary === 'payoop'}
                    id="horizontal-list-radio-oop"
                    type="radio"
                    value="payoop"
                    name="list-radio"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    htmlFor="horizontal-list-radio-oop"
                    className="py-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Out of Pocket
                  </label>
                </div>
              </div>
            </li>
            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
              <div className="flex items-center justify-center h-full px-4 py-2">
                <div className="flex items-center gap-2">
                  <input
                    onChange={handlePrimaryMethodChange}
                    checked={primary === 'neither'}
                    id="horizontal-list-radio-neither"
                    type="radio"
                    value="neither"
                    name="list-radio"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    htmlFor="horizontal-list-radio-neither"
                    className="py-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Neither
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </div>

        {/* Second Radio Group */}
        <div className="flex flex-col w-7/12">
          <h3 className="mb-2 font-semibold text-gray-900 dark:text-white">
            Insurance Verification Status:
          </h3>
          <ul className="w-full text-sm font-medium text-gray-900 bg-white border border-red-500 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
              <div className="flex items-center justify-center h-full px-4 py-2">
                <div className="flex items-center gap-2">
                  <input
                    onChange={handleVerificationStatusChange}
                    checked={verificationStatus === 'alertBilling'}
                    id="alertBilling"
                    type="radio"
                    value="alertBilling"
                    name="verificationStatus"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    htmlFor="alertBilling"
                    className="py-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Alert Billing
                  </label>
                  <div className="group relative">
                    <InfoIcon 
                      className="w-4 h-4 text-gray-500 hover:text-blue-600 cursor-help" 
                    />
                    <div className="invisible group-hover:visible absolute left-1/2 -translate-x-1/2 bottom-full mb-2 px-3 py-2 text-sm text-white bg-gray-900 rounded-lg whitespace-nowrap">
                      Insurance Verification Needed
                      <div className="absolute left-1/2 -translate-x-1/2 top-full w-0 h-0 border-8 border-transparent border-t-gray-900"></div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
              <div className="flex items-center justify-center h-full px-4 py-2">
                <div className="flex items-center gap-2">
                  <input
                    onChange={handleVerificationStatusChange}
                    checked={verificationStatus === 'alertPC'}
                    id="alertPC"
                    type="radio"
                    value="alertPC"
                    name="verificationStatus"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    htmlFor="alertPC"
                    className="py-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Alert PC
                  </label>
                  <div className="group relative">
                    <InfoIcon 
                      className="w-4 h-4 text-gray-500 hover:text-blue-600 cursor-help" 
                    />
                    <div className="invisible group-hover:visible absolute left-1/2 -translate-x-1/2 bottom-full mb-2 px-3 py-2 text-sm text-white bg-gray-900 rounded-lg whitespace-nowrap">
                      Insurance Verification Complete
                      <div className="absolute left-1/2 -translate-x-1/2 top-full w-0 h-0 border-8 border-transparent border-t-gray-900"></div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="w-full">
              <div className="flex items-center justify-center h-full px-4 py-2">
                <div className="flex items-center gap-2">
                  <input
                    onChange={handleVerificationStatusChange}
                    checked={verificationStatus === 'reportedBenefits'}
                    id="reportedBenefits"
                    type="radio"
                    value="reportedBenefits"
                    name="verificationStatus"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    htmlFor="reportedBenefits"
                    className="py-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Reported Benefits
                  </label>
                  <div className="group relative">
                    <InfoIcon 
                      className="w-4 h-4 text-gray-500 hover:text-blue-600 cursor-help" 
                    />
                    <div className="invisible group-hover:visible absolute right-0 bottom-full mb-2 px-3 py-2 text-sm text-white bg-gray-900 rounded-lg whitespace-nowrap">
                      Benefits have been reported and documented
                      <div className="absolute right-2 top-full w-0 h-0 border-8 border-transparent border-t-gray-900"></div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="flex justify-center border-b border-gray-200">
        <button
          className={`px-4 py-2 font-medium text-sm ${
            activeTab === 'payinsurance'
              ? 'text-blue-500 border-b-2 border-blue-500'
              : 'text-gray-500 hover:text-gray-700'
          }`}
          onClick={() => handleTabClick('payinsurance')}
        >
          Via Insurance
        </button>
        <button
          className={`px-4 py-2 font-medium text-sm ${
            activeTab === 'payoop'
              ? 'text-blue-500 border-b-2 border-blue-500'
              : 'text-gray-500 hover:text-gray-700'
          }`}
          onClick={() => handleTabClick('payoop')}
        >
          Via OOP
        </button>
      </div>
      <div className="p-6">{renderTabContent()}</div>
    </div>
  );
};

export default STInsuranceTable;