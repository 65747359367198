import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import HomePage from './components/HomePage';
import CalendarPage from './components/CalendarPage'; 
import PatientProfile from './components/PatientProfile';
import ProfileTable from './components/ProfileTable';
import Chat from './components/Chat';
import HR from './components/HR';
import EmployeeProfile from './components/EmployeeProfile';
import EmployeeProfileTabs from './components/EmployeeProfileTabs';
import Alerts from './components/Alerts';
import { Authenticator } from '@aws-amplify/ui-react';
import AllPatients from './components/AllPatients';
import DemographicsTab from './components/ProfileTableComps/DemographicsTab';
import BillingTab from './components/ProfileTableComps/BillingTab';
import NotesTab from './components/ProfileTableComps/NotesTab';
import ScheduleTab from './components/ProfileTableComps/ScheduleTab';
import TwoProvView from './components/TwoProvView'
import WeekView from './components/WeekView';
import CreateNote from './components/CreateNote';
import Forms from './components/Forms';
import ViewNote from './components/ViewNote';
import Charge from './components/Charge';
import { UserProvider } from './components/UserContext';
import CaptureCharge from './components/CaptureCharge';
import ModifyEvent from './components/ModifyEvent';
import ChargingTable from './components/ChargingTable';
import Reports from './components/Reports';
import ProfilePictureUpload from './components/ProfilePictureUpload';
import EncounterHistory from './components/EncounterHistory';
import { AuthGuard } from './components/AuthGuard';

const App = () => {
  return (
    <Authenticator>
      {({ signOut }) => (
        <UserProvider>
          <Router>
            <Routes>
              {/* Public route - no AuthGuard */}
              <Route path="/" element={<LoginPage />} />

              {/* Protected routes - with AuthGuard */}
              <Route path="/home" element={
                <AuthGuard> <HomePage /> </AuthGuard>
              } />
              <Route path="/demographicstab" element={
                <AuthGuard> <DemographicsTab /> </AuthGuard>
              } />
              <Route path="/billingtab" element={
                <AuthGuard> <BillingTab /> </AuthGuard>
              } />
              <Route path="/notestab" element={
                <AuthGuard> <NotesTab /> </AuthGuard>
              } />
              <Route path="/scheduletab" element={
                <AuthGuard> <ScheduleTab /> </AuthGuard>
              } />
              <Route path="/patients" element={
                <AuthGuard> <AllPatients /> </AuthGuard>
              } />
              <Route path="/calendar" element={
                <AuthGuard> <CalendarPage /> </AuthGuard>
              } />
              <Route path="/chat" element={
                <AuthGuard> <Chat /> </AuthGuard>
              } />
              <Route path="/patients/:id" element={
                <AuthGuard> <PatientProfile /> </AuthGuard>
              } />
              <Route path="/employee/:id" element={
                <AuthGuard> <EmployeeProfile /> </AuthGuard>
              } />
              <Route path="/createnote" element={
                <AuthGuard> <CreateNote /> </AuthGuard>
              } />
              <Route path="/viewnote/:id" element={
                <AuthGuard> <ViewNote /> </AuthGuard>
              } />
              <Route path="/hr" element={
                <AuthGuard> <HR /> </AuthGuard>
              } />
              <Route path="/employeeprofiletabs" element={
                <AuthGuard> <EmployeeProfileTabs /> </AuthGuard>
              } />
              <Route path="/alerts" element={
                <AuthGuard> <Alerts /> </AuthGuard>
              } />
              <Route path="/profiletable" element={
                <AuthGuard> <ProfileTable /> </AuthGuard>
              } />
              <Route path="/twoprovview" element={
                <AuthGuard> <TwoProvView /> </AuthGuard>
              } />
              <Route path="/weekview" element={
                <AuthGuard> <WeekView /> </AuthGuard>
              } />
              <Route path="/forms" element={
                <AuthGuard> <Forms /> </AuthGuard>
              } />
              <Route path="/charge" element={
                <AuthGuard> <Charge /> </AuthGuard>
              } />
              <Route path="/capturecharge/:id" element={
                <AuthGuard><CaptureCharge /></AuthGuard>
              } />
              <Route path="/modifyevent/:id" element={
                <AuthGuard><ModifyEvent /></AuthGuard>
              } />
              <Route path="/chargingtable" element={
                <AuthGuard><ChargingTable /></AuthGuard>
              } />
              <Route path="/reports" element={
                <AuthGuard><Reports /></AuthGuard>
              } />
              <Route path="/profilepictureupload" element={
                <AuthGuard><ProfilePictureUpload /></AuthGuard>
              } />
              <Route path="/encounterhistory" element={
                <AuthGuard><EncounterHistory /></AuthGuard>
              } />
            </Routes>
          </Router>
        </UserProvider>
      )}
    </Authenticator>
  );
};

export default App;