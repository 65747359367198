import React, { useEffect, useState } from 'react';
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from './Header';
import { Check } from 'lucide-react';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4">
      <div className="bg-white w-full max-w-2xl rounded-2xl shadow-xl transform transition-all">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 p-2 rounded-full hover:bg-gray-100 transition-colors"
        >
          ×
        </button>
        {children}
      </div>
    </div>
  );
};

const Charge = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { eventData, amount, billingPath } = location.state || {};

  const [paymentAmount, setPaymentAmount] = useState('');
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [billingUpdateStatus, setBillingUpdateStatus] = useState(null);
  const [error, setError] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [showSaveCardPrompt, setShowSaveCardPrompt] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [customerData, setCustomerData] = useState({
    givenName: '',
    familyName: '',
    phoneNumber: '',
    email: '',
    addressLine: '',
    country: '',
    city: '',
    postalCode: '',
    cardHolderName: '',
  });
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    if (amount) {
      setPaymentAmount(amount.toString());
    }
  }, [amount]);

  const handlePaymentSubmit = async (token) => {
    setPaymentStatus(null);
    setError(null);
    setPaymentDetails(null);
  
    try {
      const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/square-payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          body: JSON.stringify({
            sourceId: token.token,
            amount: Math.round(parseFloat(paymentAmount) * 100),
            currency: 'USD',
          })
        }),
      });

      const result = await response.json();
      console.log("Response result:", result);
  
      if (result.statusCode === 200) {
        // Parse the body string to JSON
        const paymentResponse = JSON.parse(result.body);
        setPaymentStatus('Payment successful');
        setPaymentDetails(paymentResponse.payment);
        setShowSaveCardPrompt(true); // won't be saving card when one is pulled **notice
        try {
          const billingResponse = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/confirm_charged_event', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              patient_id: eventData.patient_id,
              amount: parseFloat(paymentAmount),
              billing_path: billingPath,
              event_sch_id: eventData.event_schedule_id
            })
          });
  
          const billingResult = await billingResponse.json();
          console.log('Billing update response:', billingResult);
          if (billingResult.statusCode === 200) {
            const billingData = JSON.parse(billingResult.body);
            setBillingUpdateStatus({
              success: true,
              updates: billingData.updates,
              event_schedule_updated: billingData.event_schedule_updated === true
            });
          } else {
            setBillingUpdateStatus({
              success: false,
              error: billingResult.error
            });
          }
        } catch (billingError) {
          console.error('Error updating billing info:', billingError);
          setBillingUpdateStatus({
            success: false,
            error: 'Failed to update billing information'
          });
        }
  
        //setShowSaveCardPrompt(true);
      } else {
        const errorResponse = JSON.parse(result.body);
        const errorDetails = errorResponse.error[0];
        setError(`${errorDetails.category}: ${errorDetails.code}`);
        setPaymentStatus('Payment Failed');
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      setError('An unexpected error occurred while processing the payment');
      setPaymentStatus('Payment Failed');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCreateCustomer = async () => {
    try {
      const requestData = {
        ...customerData,
        sourceID: paymentDetails.id,
        patientID: eventData.patient_id
      };
      
      console.log("Creating customer: ", requestData);
      
      const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/square_create_customer_card', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData)
      });
      
      const data = await response.json();
      
      if (response.ok) {
        console.log('Data', data);
        console.log('Success! Customer ID:', data.customerID);
        console.log('Card ID:', data.cardID);
        alert(`Customer Created and Card Saved for ${customerData.cardHolderName}`)
        // Handle success - maybe update UI or state here
      } else {
        console.error('Failed to create customer:', data.error);
        // Handle error - maybe update UI or state here
      }
    } catch (error) {
      console.error('Error creating customer:', error);
      // Handle any network or other errors
    }
  };

  const formatBillingPath = (path) => {
    if (!path) return null;
    
    const parts = path.split('.');
    return {
      service: parts[0], // 'ST' or 'OT'
      method: parts[1], // 'ViaIns'
      network: parts[2], // 'InNetwork' or 'OutOfNetwork'
      deductType: parts[3] // 'IndDeductAmt' or 'FamDeductAmt'
    };
  };

  return (
    <div>
      <Header />
      <div className="container mx-auto p-4 mt-20">
        <h1 className="text-2xl font-bold mb-4">Process Payment</h1>
        <div className="mb-4">
          <label htmlFor="amount" className="block mb-2">Payment Amount ($)</label>
          <input
            type="number"
            id="amount"
            value={paymentAmount}
            onChange={(e) => setPaymentAmount(e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="Enter amount"
            // readOnly={amount !== undefined}
          />
        </div>
        {billingPath && (
  <div className="mb-4 p-4 bg-blue-50 rounded">
    <h2 className="text-lg font-semibold mb-2">Billing Details</h2>
    <div className="grid grid-cols-2 gap-2 text-sm">
      {(() => {
        const billing = formatBillingPath(billingPath);
        return (
          <>
            <p><span className="font-medium">Service:</span> {billing.service}</p>
            <p><span className="font-medium">Payment Method:</span> {billing.method}</p>
            <p><span className="font-medium">Network Type:</span> {billing.network}</p>
            <p><span className="font-medium">Deductible Type:</span> {billing.deductType}</p>
          </>
        );
      })()}
    </div>
  </div>
)}
        
        {/* Optional: Display event details */}
        {eventData && (
          <div className="mb-4 p-4 bg-gray-50 rounded">
            <h2 className="text-lg font-semibold mb-2">Event Details</h2>
            <div className="grid grid-cols-2 gap-2 text-sm">
              <p><span className="font-medium">Service:</span> {eventData.event_name}</p>
              <p><span className="font-medium">Patient ID:</span> {eventData.patient_id}</p>
              <p><span className="font-medium">Provider:</span> {eventData.pr_id}</p>
              <p><span className="font-medium">Date:</span> {new Date(eventData.start_time).toLocaleDateString()}</p>
              <p><span className="font-medium">Charge Amount:</span> {amount}</p>
              <p><span className="font-medium">Event ID Charged:</span> {eventData.event_schedule_id}</p>
            </div>
          </div>
        )}

        <PaymentForm
          applicationId="sandbox-sq0idb-jvKqLcpPJqY71l_PE2Imtg"
          locationId="L14EWD9HX4G50" // Replace with your actual location ID
          environment="sandbox"
          cardTokenizeResponseReceived={async (token, buyer) => {
            await handlePaymentSubmit(token);
          }}
        >
          <CreditCard />
        </PaymentForm>
        
        {paymentStatus === 'Payment successful' && (
          <div className="mt-4 space-y-2">
            <div className="p-2 bg-green-100 text-green-700 rounded">
              {paymentStatus}
            </div>
            {paymentDetails && (
              <div className="p-2 bg-blue-50 text-blue-700 rounded">
                <p>Payment ID: {paymentDetails.id}</p>
                <p>Amount: ${(paymentDetails.amount_money.amount / 100).toFixed(2)}</p>
                <p>Status: {paymentDetails.status}</p>
                <p>Card: {paymentDetails.card_details.card.card_brand} ending in {paymentDetails.card_details.card.last_4}</p>
              </div>
            )}
            {billingUpdateStatus && (
  <div className={`p-2 rounded ${billingUpdateStatus.success ? 'bg-green-50 text-green-700' : 'bg-yellow-50 text-yellow-700'}`}>
    <h3 className="font-semibold mb-1">Patient Billing Information Update</h3>
    {billingUpdateStatus.success ? (
      <>
        {billingUpdateStatus.updates.map((update, index) => {
          const pathParts = update.path.split('.');
          const updateType = pathParts[pathParts.length - 1];
          
          return (
            <div key={index} className="mb-2">
              {updateType.includes('DeductAmt') && (
                <p>Updated {pathParts.slice(0, -1).join(' → ')} Deductible: ${update.value}</p>
              )}
              {updateType === 'Sessions' && (
                <p>Updated {pathParts.slice(0, -1).join(' → ')} Sessions: {update.value}</p>
              )}
              {updateType === 'SessNumber' && (
                <p>Updated Authorization Sessions: {update.value}</p>
              )}
            </div>
          );
        })}
        {billingUpdateStatus.event_schedule_updated === true && (
          <p className="mt-1 text-sm">Event has been marked as charged</p>
        )}
      </>
    ) : (
      <p>Note: Payment was successful, but billing update failed: {billingUpdateStatus.error}</p>
    )}
  </div>
)}
          </div>
        )}
        
        {paymentStatus === 'Payment Failed' && (
          <div className="mt-4 space-y-2">
            <div className="p-2 bg-red-100 text-red-700 rounded">
              {paymentStatus}
            </div>
            {error && (
              <div className="p-2 bg-red-50 text-red-700 rounded">
                Error: {error}
              </div>
            )}
          </div>
        )}

        {showSaveCardPrompt && (
          <div className="mt-4 p-4 bg-gray-50 rounded">
            <p className="mb-4">Would you like to save this card for future use?</p>
            <div className="space-x-4">
              <button
                onClick={() => navigate('/chargingtable')}
                className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
              >
                No
              </button>
              <button
                onClick={() => {
                  setShowSaveCardPrompt(false);
                  setShowCustomerModal(true);
                }}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Yes
              </button>
            </div>
          </div>
        )}

        <Modal
          isOpen={showCustomerModal}
          onClose={() => setShowCustomerModal(false)}
        >
          <div className="p-8"> {/* Increased padding */}
            <h2 className="text-2xl font-bold text-gray-800 mb-6">Save Payment Method</h2>
            
            {/* Progress Steps in a contained width */}
            <div className="mb-8">
              <div className="max-w-[400px] mx-auto"> {/* Center container with max width */}
                <div className="flex items-center justify-between relative">
                  {[
                    { id: 1, title: 'Customer Info' },
                    { id: 2, title: 'Billing Details' },
                    { id: 3, title: 'Confirm Card' }
                  ].map((step) => (
                    <div key={step.id} className="flex flex-col items-center relative z-10">
                      <div className={`w-10 h-10 rounded-full flex items-center justify-center border-2 transition-all duration-200
                        ${currentStep >= step.id 
                          ? 'bg-blue-500 border-blue-500 text-white shadow-lg shadow-blue-200' 
                          : 'border-gray-300 text-gray-300'}`}
                      >
                        {currentStep > step.id ? (
                          <Check className="w-6 h-6 stroke-[3]" />
                        ) : (
                          <span className="text-lg font-semibold">{step.id}</span>
                        )}
                      </div>
                      <span className={`mt-2 text-sm font-medium transition-colors duration-200
                        ${currentStep >= step.id ? 'text-blue-500' : 'text-gray-400'}`}>
                        {step.title}
                      </span>
                    </div>
                  ))}
                  {/* Replace the single progress line with two lines */}
                <div className="absolute top-4 left-[15.5%] right-[53.9%] h-2 bg-gray-200 -z-10 rounded-full">
                  <div 
                    className="absolute top-0 left-0 h-full bg-blue-500 rounded-full transition-all duration-500"
                    style={{ 
                      width: currentStep > 1 ? '100%' : '0%'
                    }}
                  />
                </div>
                {/* Second line - between steps 2 and 3 */}
                <div className="absolute top-4 left-[55.1%] right-[15.5%] h-2 bg-gray-200 -z-10 rounded-full">
                  <div 
                    className="absolute top-0 left-0 h-full bg-blue-500 rounded-full transition-all duration-500"
                    style={{ 
                      width: currentStep > 2 ? '100%' : '0%'
                    }}
                  />
                </div>
                </div>
              </div>
            </div>

            {/* Form Content with more spacing and better organization */}
            <div className="mb-8 bg-gray-50 p-6 rounded-xl">
              {currentStep === 1 ? (
                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <label className="block mb-2 font-medium text-gray-700">First Name</label>
                    <input type="text" name="givenName" value={customerData.givenName} onChange={handleInputChange} className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all" placeholder="Enter first name" />
                  </div>
                  <div>
                    <label className="block mb-2 font-medium text-gray-700">Last Name</label>
                    <input type="text" name="familyName" value={customerData.familyName} onChange={handleInputChange} className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all" placeholder="Enter last name" />
                  </div>
                  <div>
                    <label className="block mb-2 font-medium text-gray-700">Phone Number</label>
                    <input type="tel" name="phoneNumber" value={customerData.phoneNumber} onChange={handleInputChange} className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all" placeholder="Enter phone number" />
                  </div>
                  <div>
                    <label className="block mb-2 font-medium text-gray-700">Email</label>
                    <input type="email" name="email" value={customerData.email} onChange={handleInputChange} className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all" placeholder="Enter email" />
                  </div>
                </div>
              ) : currentStep === 2 ? (
                <div className="space-y-6">
                  <div className="grid grid-cols-2 space-x-2">
                    <div className="mb-6">
                      <label className="block mb-2 font-medium text-gray-700">Address Line</label>
                      <input type="text" name="addressLine" value={customerData.addressLine} onChange={handleInputChange} className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all" placeholder="Enter street address" />
                    </div>
                    <div className="mb-6">
                      <label className="block mb-2 font-medium text-gray-700">Country</label>
                      <select name="country" value={customerData.country} onChange={handleInputChange} className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all">
                        <option value="">Select a country</option>
                        <option value="US">United States (US)</option>
                        <option value="CA">Canada (CA)</option>
                        <option value="GB">United Kingdom (GB)</option>
                        <option value="FR">France (FR)</option>
                      </select>
                    </div>
                    <div>
                      <label className="block mb-2 font-medium text-gray-700">City</label>
                      <input type="text" name="city" value={customerData.city} onChange={handleInputChange} className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all" placeholder="Enter city" />
                    </div>
                    <div>
                      <label className="block mb-2 font-medium text-gray-700">Postal Code</label>
                      <input type="text" name="postalCode" value={customerData.postalCode} onChange={handleInputChange} className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all" placeholder="Enter postal code" />
                    </div>
                  </div>
                  <div>
                    <label className="block mb-2 font-medium text-gray-700">Card Holder Full Name</label>
                    <input type="text" name="cardHolderName" value={customerData.cardHolderName} onChange={handleInputChange} className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all" placeholder="Enter cardholder's name" />
                  </div>
                </div>
              ) : (
                <div className="space-y-6">
                  <div>
                    <label className="block mb-2 font-medium text-gray-700">Card Holder Full Name</label>
                    <input type="text" name="cardHolderName" value={customerData.cardHolderName} onChange={handleInputChange} className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all" placeholder="Enter cardholder's name" />
                  </div>
                  <div>
                    <label className="block mb-2 font-medium text-gray-700">Card Details Review</label>
                    <div className="p-4 bg-gray-100 rounded-lg">
                      <p className="text-sm text-gray-600">Please review your card ending in {paymentDetails?.card_details?.card?.last_4}</p>
                      <p className="text-sm text-gray-600">Card Type: {paymentDetails?.card_details?.card?.card_brand}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Navigation Buttons with improved styling */}
            <div className="flex justify-between mt-8 px-4">
              <button
                onClick={() => setCurrentStep(curr => curr - 1)}
                className={`px-6 py-3 rounded-lg font-medium transition-all duration-200
                  ${currentStep === 1 
                    ? 'opacity-0 pointer-events-none' 
                    : 'text-gray-600 hover:bg-gray-100'}`}
              >
                ← Previous
              </button>
              <button
                onClick={() => {
                  if (currentStep === 3) {
                    handleCreateCustomer();
                  } else {
                    setCurrentStep(curr => curr + 1);
                  }
                }}
                className="px-6 py-3 bg-blue-500 text-white rounded-lg font-medium hover:bg-blue-600 
                  transform transition-all duration-200 hover:shadow-lg hover:-translate-y-0.5"
              >
                {currentStep === 3 ? 'Save Card' : 'Continue →'}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Charge;