import React from 'react';
import STInsuranceTable from './STInsuranceTable';

const STInsurance = ({ patient }) => {
  return (
    <div>
      <STInsuranceTable patient={patient}/>
    </div>
  );
};

export default STInsurance;