import React, { useState, useEffect, useCallback } from 'react';
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from '@radix-ui/react-collapsible';
import { ChevronDoubleRightIcon, FolderOpenIcon } from '@heroicons/react/24/outline';
import { format, parseISO, addMonths } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { Folder, ChevronDown } from 'lucide-react';

const TherapyContent = ({ type }) => (
  <div className="p-4 space-y-4">
    <div>
      <label className="block text-sm font-medium text-gray-700">Deductible:</label>
      <input type="text" className="mt-1 block w-full border-gray-300 rounded-md shadow-sm" placeholder="remaining/left (Individual/shared)" />
    </div>
    <div>
      <label className="block text-sm font-medium text-gray-700">Copay:</label>
      <input type="text" className="mt-1 block w-full border-gray-300 rounded-md shadow-sm" />
    </div>
    <div>
      <label className="block text-sm font-medium text-gray-700"># of Sessions Remaining:</label>
      <input type="text" className="mt-1 block w-full border-gray-300 rounded-md shadow-sm" placeholder="___/(# allotted) (Individual/shared)" />
    </div>
    <div>
      <label className="block text-sm font-medium text-gray-700">Authorization #:</label>
      <input type="text" className="mt-1 block w-full border-gray-300 rounded-md shadow-sm" />
    </div>
    <div>
      <label className="block text-sm font-medium text-gray-700">Authorization Start Date:</label>
      <input type="date" className="mt-1 block w-full border-gray-300 rounded-md shadow-sm" />
    </div>
    <div>
      <label className="block text-sm font-medium text-gray-700">Authorization End Date:</label>
      <input type="date" className="mt-1 block w-full border-gray-300 rounded-md shadow-sm" />
    </div>
    <button className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">+ Add New (auth)</button>
    <div>
      <label className="block text-sm font-medium text-gray-700">Notes:</label>
      <textarea className="mt-1 block w-full border-gray-300 rounded-md shadow-sm" rows="4"></textarea>
    </div>
  </div>
);

const OOPContent = ({ type }) => (
  <div className="p-4 space-y-4">
    <div>
      <label className="block text-sm font-medium text-gray-700"># of sessions:</label>
      <input type="number" className="mt-1 block w-full border-gray-300 rounded-md shadow-sm" />
    </div>
    <div>
      <label className="block text-sm font-medium text-gray-700">{type} Rate:</label>
      <input type="number" className="mt-1 block w-full border-gray-300 rounded-md shadow-sm" />
    </div>
    <div>
      <label className="block text-sm font-medium text-gray-700">Total Collected:</label>
      <input type="number" className="mt-1 block w-full border-gray-300 rounded-md shadow-sm" />
    </div>
    <div>
      <label className="block text-sm font-medium text-gray-700">Notes:</label>
      <textarea className="mt-1 block w-full border-gray-300 rounded-md shadow-sm" rows="4"></textarea>
    </div>
    <div>
      <label className="block text-sm font-medium text-gray-700">Special Charge (Add Service like PG), Rate:</label>
      <input type="number" className="mt-1 block w-full border-gray-300 rounded-md shadow-sm" />
    </div>
  </div>
);

const CollapsibleSection = ({ title, children, level = 0, type = 'default' }) => {
  const getHeaderColor = () => {
    switch (type) {
      case 'insurance': return 'text-blue-600';
      case 'oop': return 'text-emerald-600';
      default: return 'text-gray-700';
    }
  };

  return (
    <Collapsible className={`w-full transition-all ${level === 0 ? "mb-6" : "mb-2"}`}>
      <CollapsibleTrigger className={`flex items-center justify-between w-full p-4 text-left rounded-lg hover:bg-gray-50 transition-colors duration-200 ${level === 0 ? "bg-white shadow-sm" : "bg-gray-50"}`}>
        <span className={`flex items-center gap-3 font-medium ${getHeaderColor()}`}>
          <Folder className={`w-5 h-5 ${level === 0 ? "text-blue-500" : "text-gray-400"}`} />
          {title}
        </span>
        <ChevronDown className="w-5 h-5 text-gray-400 transition-transform duration-200 ease-in-out group-data-[state=open]:rotate-180" />
      </CollapsibleTrigger>
      <CollapsibleContent className={`overflow-hidden transition-all ${level === 0 ? "mt-2" : "ml-4"}`}>
        <div className={`space-y-2 ${level === 0 ? "px-2" : "pl-2"}`}>
          {children}
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};

const EventDisplay = ({ patientID }) => {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);

  const fetchEvents = useCallback(async () => {
    if (!patientID) {
      setError('No patient ID provided');
      return;
    }

    const today = new Date();
    const endDate = addMonths(today, 1);

    const payload = {
      patient_id: patientID,
      start_date: format(today, 'yyyy-MM-dd'),
      end_date: format(endDate, 'yyyy-MM-dd')
    };

    try {
      const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get_patient_events', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const parsedData = JSON.parse(data);
      
      if (parsedData.length > 0) {
        setEvents(parsedData);
      } else {
        setError('No upcoming appointments found');
      }
    } catch (error) {
      console.error('Error fetching events:', error);
      setError('Failed to fetch appointment data');
    }
  }, [patientID]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  const changeEventStatus = async (uniqueEventId, excepDate, cancelReason, excStatus) => {
    const [eventId, eventSchId] = uniqueEventId.split('-');
    
    const payload = {
      event_id: eventId,
      event_schedule_id: eventSchId,
      excep_date: excepDate,
      cancel_reason: cancelReason,
      exc_status: excStatus,
    };
  
    try {
      const response = await fetch(
        'https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/create_exception_event',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('create_exception_event response:', data);
  
      // Refresh the events data after status change
      fetchEvents();
    } catch (error) {
      console.error('Error updating event status:', error);
      throw error;
    }
  };

  const handleStatusChange = useCallback(async (e, event) => {
    const newStatus = e.target.value;
    console.log('New status selected:', newStatus, 'for event on', format(parseISO(event.start_time), 'yyyy-MM-dd'));
  
    let excStatus;
    let cancelReason = '';
    switch (newStatus) {
      case 'Canceled by Patient':
      case 'Canceled by Provider':
        excStatus = 'C';
        cancelReason = newStatus;
        break;
      case 'Scheduled':
        excStatus = 'A';
        break;
      case 'No Show':
        excStatus = 'N';
        break;
      case 'Confirmed':
        excStatus = 'Y';
        break;
      default:
        excStatus = 'A'; // Default to Scheduled
    }
  
    const excepDate = format(parseISO(event.start_time), 'yyyy-MM-dd');
    const uniqueEventId = `${event.event_id}-${event.event_schedule_id}`;
  
    try {
      await changeEventStatus(uniqueEventId, excepDate, cancelReason, excStatus);
    } catch (error) {
      console.error('Failed to update event status:', error);
    }
  }, [changeEventStatus]);

  const getEventStatus = (event) => {
    if (event.ev_status === 'A') return 'Scheduled';
    if (event.ev_status === 'Y') return 'Confirmed';
    if (event.ev_status === 'C') return event.cancel_reason || 'Canceled';
    if (event.ev_status === 'N') return 'No Show';
    return 'Scheduled'; // Default value
  };

  const getEventClass = (event) => {
    return event.ev_status === 'C' || event.ev_status === 'N' ? 'bg-red-50' : 'bg-blue-50';
  };

  if (error) {
    return <p className="text-gray-500 italic">{error}</p>;
  }

  if (events.length === 0) {
    return <p className="text-gray-500 italic">Loading appointment data...</p>;
  }

  return (
    <div className="space-y-4">
      {events.map((event, index) => {
        const startTime = parseISO(event.start_time);
        const endTime = parseISO(event.end_time);
        const eventStatus = getEventStatus(event);
        const eventClass = getEventClass(event);

        return (
          <div key={index} className={`${eventClass} rounded-lg p-4 shadow transition-all duration-200 hover:shadow-md`}>
            <div className="flex justify-between items-center mb-2">
              <h3 className="font-semibold text-lg text-gray-800">
                {event.event_name} | {format(startTime, 'MMMM do')}
              </h3>
              <span className="text-sm text-gray-600">
                {format(startTime, 'h:mm a')} - {format(endTime, 'h:mm a')}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <span className="text-sm text-gray-600 mr-2">Status:</span>
                <select 
                  className="form-select text-sm border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
                  onChange={(e) => handleStatusChange(e, event)} 
                  value={eventStatus}
                >
                  <option value="Confirmed">Confirmed</option>
                  <option value="Scheduled">Scheduled</option>
                  <option value="Canceled by Patient">Canceled by Patient</option>
                  <option value="Canceled by Provider">Canceled by Provider</option>
                  <option value="No Show">No Show</option>
                </select>
              </div>
              <div>
                <span className="font-bold text-green-600">Appointment Charge: ${event.charge || 'N/A'}</span>
                <button className="ml-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
                  Go To Charge
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const BillingTab = ({ patient }) => {
  if (!patient) {
    return <div>Loading patient data...</div>;
  }

  return (
    <div className="p-6 max-w-5xl mx-auto">
      <h2 className="text-2xl font-bold mb-8 text-gray-900">Billing Summary</h2>

      <div className='space-y-6'>
        <CollapsibleSection title="Speech Therapy" level={0}>
          <CollapsibleSection title="Insurance" level={1} type="insurance">
            <TherapyContent type="ST" />
          </CollapsibleSection>
          <CollapsibleSection title="Out of Pocket" level={1} type="oop">
            <OOPContent type="ST" />
          </CollapsibleSection>
        </CollapsibleSection>

        <CollapsibleSection title="Occupational Therapy" level={0}>
          <CollapsibleSection title="Insurance" level={1} type="insurance">
            <TherapyContent type="OT" />
          </CollapsibleSection>
          <CollapsibleSection title="Out of Pocket" level={1} type="oop">
            <OOPContent type="OT" />
          </CollapsibleSection>
        </CollapsibleSection>

        <CollapsibleSection title="Peer Group" level={0}>
          <CollapsibleSection title="Insurance" level={1} type="insurance">
            <TherapyContent type="PG" />
          </CollapsibleSection>
          <CollapsibleSection title="Out of Pocket" level={1} type="oop">
            <OOPContent type="PG" />
          </CollapsibleSection>
        </CollapsibleSection>
      </div>
      <div className='mt-12'>
        <h3 className="text-xl font-semibold mb-6 text-gray-900">Upcoming Appointments for {patient.FirstName} {patient.LastName}</h3>
        <EventDisplay patientID={patient.PatientID} />
      </div>
    </div>
  );
};

export default BillingTab;