import { format, addDays, subDays, addMonths, subMonths } from 'date-fns';
import { startOfMonth, endOfMonth, startOfWeek, endOfWeek, eachDayOfInterval, isSameDay, isSameMonth } from 'date-fns';
import { React, useState, useEffect } from 'react';
import axios from 'axios';
import {
    ChevronLeftIcon,
    ChevronRightIcon
  } from '@heroicons/react/24/solid';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }


const Calendar = ({ onDateSelect, onProvidersSelect }) => {

    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [providers, setProviders] = useState([]);
    const [selectedProviders, setSelectedProviders] = useState([]);

    const formatProviderName = (originalName) => {
      // Trim and split the name
      const nameParts = originalName.trim().split(' ');
      // If only one name, return it as-is
      if (nameParts.length === 1) {
          return originalName;
      }
      // Take the last part as the last name
      const lastName = nameParts[nameParts.length - 1];
      // Combine all parts except the last as the first name
      const firstName = nameParts.slice(0, -1).join(' ');
      // Return in 'LastName, FirstName' format
      return `${lastName}, ${firstName}`;
  };

    const updateCurrentDate = (date) => {
        setSelectedDate(date);
        onDateSelect(date);
      };

    const renderMini = () => {
        const monthStart = startOfMonth(selectedDate);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart, { weekStartsOn: 1 }); // Start on Monday
        const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });
      
        const rows = [];
        let days = [];
        let day = startDate;
        const today = new Date();
      
        while (day <= endDate) {
          for (let i = 0; i < 7; i++) {
            const cloneDay = day;
            days.push(
              <div
                key={day.toString()}
                className={classNames(
                  'py-1.5',
                  !isSameMonth(day, monthStart) && 'bg-gray-50 text-gray-400',
                  isSameMonth(day, monthStart) && 'bg-white',
                  isSameDay(day, today) && 'text-indigo-600',
                  isSameDay(day, selectedDate) && 'font-semibold'
                )}
              >
                <button
                  onClick={() => updateCurrentDate(cloneDay)}
                  className="w-full h-full flex items-center justify-center"
                >
                  <time
                    dateTime={format(day, 'yyyy-MM-dd')}
                    className={classNames(
                      'flex h-7 w-7 items-center justify-center rounded-full',
                      isSameDay(day, selectedDate) && 'bg-blue-700 text-white'
                    )}
                  >
                    {format(day, 'd')}
                  </time>
                </button>
              </div>
            );
            day = addDays(day, 1);
          }
          rows.push(
            <div key={day.toString()} className="grid grid-cols-7 gap-px">
              {days}
            </div>
          );
          days = [];
        }
      
        return (
          <div className="bg-gray-200 p-px rounded-lg shadow ring-1 ring-gray-200">
            {rows}
          </div>
        );
      };

    useEffect(() => {
      const fetchProviders = async () => {
          try {
              const params = new URLSearchParams(); 

              const response = await axios.get(`https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get_providers?${params.toString()}`);

              let providersArray = [];

              if (typeof response.data === 'string') {
                  // Custom parsing for the specific string format
                  const match = response.data.match(/body=(\[.*?\])/s);
                  if (match && match[1]) {
                      try {
                          const bodyContent = match[1].replace(/'/g, '"'); // Replace single quotes with double quotes
                          const parsedBody = JSON.parse(bodyContent);
                          if (Array.isArray(parsedBody)) {
                              providersArray = parsedBody.map(provider => ({
                                  id: provider.pr_id,
                                  name: formatProviderName(provider.pr_name)
                              }));
                          }
                      } catch (parseError) {
                          console.error('Error parsing body content:', parseError);
                      }
                  } else {
                      console.error('Unable to extract body content from response');
                  }
              } else if (typeof response.data === 'object' && response.data !== null) {
                  // Handle case where response.data is already an object
                  if (response.data.body && Array.isArray(response.data.body)) {
                      providersArray = response.data.body.map(provider => ({
                          id: provider.pr_id,
                          name: formatProviderName(provider.pr_name)
                      }));
                  } else {
                      console.error('Unexpected data structure:', response.data);
                  }
              } else {
                  console.error('Unexpected response type:', typeof response.data);
              }
              // alphabetically sort
              providersArray.sort((a, b) => a.name.localeCompare(b.name));
              setProviders(providersArray);
          } catch (error) {
              console.error('Error calling Lambda function:', error);
              setProviders([]);
          }
      };

      fetchProviders();
    }, []);

    const handleProviderSelect = (providerId) => {
      setSelectedProviders(prev => {
        const updatedProviders = prev.includes(providerId)
            ? prev.filter(id => id !== providerId)
            : [...prev, providerId];
        
        // Call the callback with the updated providers array
        onProvidersSelect(updatedProviders);
        return updatedProviders;
    });
    };
    
    return (
      <div>
        <div className="flex items-center justify-center pt-8 px-4">
            <div className="bg-gray-100 max-w-sm w-full shadow-xl">
            {/* <h1 className="text-md text-center font-semibold text-gray-900">
                <time className="hidden sm:inline">
                Today is : {format(currentDate, 'MMMM d, yyyy')}
                </time>
            </h1> 
            <h1 className="text-md -mt-8 text-center font-semibold text-gray-900">
                <time className="hidden sm:inline">
                You are viewing : {format(selectedDate, 'MMMM d, yyyy')}
                </time>
            </h1> */}
            <div className="md:py-4 md:px-8 px-5 dark:bg-gray-700 bg-gray-50 rounded-b">
                <div className="w-full">
                  <h3 className="text-lg font-semibold mb-4 text-center">Providers</h3>
                  <div className="w-full max-h-80 overflow-y-auto border border-gray-300 rounded">
                    {providers.map(provider => (
                        <div 
                            key={provider.id} 
                            className="flex items-center text-center pb-2 pt-2 px-2 hover:bg-gray-300"
                        >
                            <input 
                                type="checkbox"
                                id={`provider-${provider.id}`}
                                checked={selectedProviders.includes(provider.id)}
                                onChange={() => handleProviderSelect(provider.id)}
                                className="mr-2 h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                            />
                            <label 
                                htmlFor={`provider-${provider.id}`} 
                                className="text-sm font-medium text-gray-700 flex-grow"
                            >
                                {provider.name}
                            </label>
                        </div>
                    ))}
                  </div>
                </div>
            </div>
            <div className="hidden max-w-md flex-none border-l border-gray-100 py-10 px-8 md:block">
                <div className="flex items-center text-center text-gray-900">
                    <button
                    type="button"
                    onClick={() => setSelectedDate(subMonths(selectedDate, 1))}
                    className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                    >
                    <span className="sr-only">Previous month</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <div className="flex-auto font-semibold">{format(selectedDate, 'MMMM yyyy')}</div>
                    <button
                    type="button"
                    onClick={() => setSelectedDate(addMonths(selectedDate, 1))}
                    className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                    >
                    <span className="sr-only">Next month</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
                <div className="mt-6 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
                    <div>M</div>
                    <div>T</div>
                    <div>W</div>
                    <div>T</div>
                    <div>F</div>
                    <div>S</div>
                    <div>S</div>
                </div>
                {renderMini()}
            </div>
            </div>
        </div>
      </div>
    );
  };
  
export default Calendar;