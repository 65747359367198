import React, { useState, useEffect } from 'react';

const DemographicsTab = ({ patient }) => {
    // Move useEffect to top
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    // Helper functions
    const parseSchools = (schoolsData) => {
        if (!schoolsData) return [];
        if (Array.isArray(schoolsData)) return schoolsData;
        try {
            const cleaned = schoolsData.replace(/\\/g, '');
            return JSON.parse(cleaned);
        } catch (error) {
            console.error('Error parsing schools:', error);
            return [];
        }
    };

    const getServiceString = (patientST, patientOT) => {
        let str = '';
        if (patientST === 'ST' && patientOT !== 'OT') {
          str = 'ST';
        } else if (patientOT === 'OT' && patientST !== 'ST') {
          str = 'OT';
        } else if (patientST === 'ST' && patientOT === 'OT') {
          str = 'ST and OT';
        }
        return str;
    };

    // Move all useState declarations to top, before any conditional returns
    const [firstName, setFirstName] = useState(patient?.FirstName || '');
    const [lastName, setLastName] = useState(patient?.LastName || '');
    const [pronunciation, setPronunciation] = useState(patient?.Pronunciation || '');
    const [dob, setDob] = useState(patient?.DOB || '');
    const [schools, setSchools] = useState(() => parseSchools(patient?.Schools));
    const [diagnosis, setDiagnosis] = useState(patient?.Diagnosis || '');
    const [parentGuardian, setParentGuardian] = useState(patient?.ParentGuardian || '');
    const [relationship, setRelationship] = useState(patient?.Relationship || '');
    const [email, setEmail] = useState(patient?.Email || '');
    const [phone, setPhone] = useState(patient?.PhoneNumber || '');
    const [services, setServices] = useState(patient?.Services || '');
    const [therLocation, setTherLocation] = useState(patient?.TherLocation || '');
    const [stTherapist, setStTherapist] = useState(patient?.ST_Therapist || '');
    const [otTherapist, setOtTherapist] = useState(patient?.OT_Therapist || '');
    const [mainConcern, setMainConcern] = useState(patient?.MainConcern || '');
    const [insuranceCompany, setInsuranceCompany] = useState(patient?.InsuranceCompany || '');
    const [fiveVerification, setFiveVerification] = useState(patient?.FiveVerification || '');
    const [fiveSumInt, setFiveSumInt] = useState(patient?.FiveSummerInterest || '');
    const [fiveFallInt, setFiveFallInt] = useState(patient?.FiveFallInterest || '');
    const [intakeStatus, setIntakeStatus] = useState(patient?.IntakeStatus || '');
    const [intakeScheduleW, setIntakeScheduleW] = useState(patient?.IntakeScheduledW || '');
    const [intakeDate, setIntakeDate] = useState(patient?.IntakeDate || '');
    const [firstStDate, setFirstStDate] = useState(patient?.FirstSt || '');
    const [firstOtDate, setFirstOtDate] = useState(patient?.FirstOt || '');
    const [inquiringServices, setInquiringServices] = useState(patient?.InquiringServices || '');
    const [whereHear, setWhereHear] = useState(patient?.WhereHear || '');
    const [hearOther, setHearOther] = useState(patient?.HearOther || '');
    const [gender, setGender] = useState(patient?.Gender || '');
    const [address, setAddress] = useState(patient?.Address || '');
    const [allergies, setAllergies] = useState(patient?.Allergies || '');
    const [pediatrician, setPediatrician] = useState(patient?.Pediatrician || '');
    const [patientID, setPatientID] = useState(patient?.PatientID || '');
    const [accOpen, setAccOpen] = useState(1);

    // Now we can have our conditional return
    if (!patient) {
        return <div>Loading patient data...</div>;
    }

    const serviceString = getServiceString(patient.ST, patient.OT);
    if (services === '') {
        setServices(serviceString);
    }

    const isIntakeScheduled = intakeStatus === 'Intake Scheduled';
    
    const handleOpen = (value) => setAccOpen(accOpen === value ? 0 : value);

    const handleAddSchool = () => {
        setSchools(prevSchools => [...prevSchools, '']);
    };

    const handleDeleteSchool = (index) => {
        setSchools(prevSchools => {
            const newArray = [...prevSchools];
            newArray.splice(index, 1);
            return newArray;
        });
    };
    
    const handleSchoolChange = (index, value) => {
        setSchools(prevSchools => {
            const updatedSchools = [...prevSchools];
            updatedSchools[index] = value;
            return updatedSchools;
        });
    };
    
    const handleSubmit = async (e) => {
    e.preventDefault();

    const formattedSchools = JSON.stringify(schools);
  
    const data = {
      patientID,
      firstName,
      lastName,
      pronunciation,
      dob,
      gender,
      diagnosis,
      address,
      allergies,
      pediatrician,
      schools: formattedSchools,
      mainConcern,
      parentGuardian,
      relationship,
      phone,
      email,
      services,
      therLocation,
      stTherapist,
      otTherapist,
      insuranceCompany,
      fiveVerification,
      fiveSumInt,
      fiveFallInt,
      intakeStatus,
      intakeScheduleW,
      intakeDate,
      inquiringServices,
      whereHear,
      hearOther,
      firstStDate,
      firstOtDate,
    };

    try {
      const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/update_patient', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      if (response.ok) {
        console.log('Patient data updated successfully');
        
        // Fetch updated patient data
        const updatedPatientResponse = await fetch(`https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/find_patient`, {
          headers: {
            PatientID: patientID,
            'Content-Type': 'application/json'
          }
        });

        if (updatedPatientResponse.ok) {
          const result = await updatedPatientResponse.json();
          const parsedBody = typeof result.body === 'string' 
            ? JSON.parse(result.body) 
            : result.body;

          if (Array.isArray(parsedBody) && parsedBody.length > 0) {
            const updatedPatient = parsedBody[0];
            
            // Update all states with fresh data
            setFirstName(updatedPatient.FirstName || '');
            setLastName(updatedPatient.LastName || '');
            setPronunciation(updatedPatient.Pronunciation || '');
            setDob(updatedPatient.DOB || '');
            setSchools(parseSchools(updatedPatient.Schools));
            setDiagnosis(updatedPatient.Diagnosis || '');
            setParentGuardian(updatedPatient.ParentGuardian || '');
            setRelationship(updatedPatient.Relationship || '');
            setEmail(updatedPatient.Email || '');
            setPhone(updatedPatient.PhoneNumber || '');
            setServices(updatedPatient.Services || '');
            setTherLocation(updatedPatient.TherLocation || '');
            setStTherapist(updatedPatient.ST_Therapist || '');
            setOtTherapist(updatedPatient.OT_Therapist || '');
            setMainConcern(updatedPatient.MainConcern || '');
            setInsuranceCompany(updatedPatient.InsuranceCompany || '');
            setFiveVerification(updatedPatient.FiveVerification || '');
            setFiveSumInt(updatedPatient.FiveSummerInterest || '');
            setFiveFallInt(updatedPatient.FiveFallInterest || '');
            setIntakeStatus(updatedPatient.IntakeStatus || '');
            setIntakeScheduleW(updatedPatient.IntakeScheduledW || '');
            setIntakeDate(updatedPatient.IntakeDate || '');
            setFirstStDate(updatedPatient.FirstSt || '');
            setFirstOtDate(updatedPatient.FirstOt || '');
            setInquiringServices(updatedPatient.InquiringServices || '');
            setWhereHear(updatedPatient.WhereHear || '');
            setHearOther(updatedPatient.HearOther || '');
            setGender(updatedPatient.Gender || '');
            setAddress(updatedPatient.Address || '');
            setAllergies(updatedPatient.Allergies || '');
            setPediatrician(updatedPatient.Pediatrician || '');
          }
        }

        // Show success message to user
        alert('Patient information updated successfully!');
      } else {
        console.error('Error updating patient data');
        alert('Failed to update patient information. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while updating patient information.');
    }
};

    return (
        <div className=''>
        <div className="grid gap-6 md:grid-cols-3">
            <div>
                <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">First Name</label>
                <input type="text" id="first_name" value={firstName} onChange={(e) => setFirstName(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="First Name" required/>
            </div>
            <div>
                <label htmlFor="last_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Last Name</label>
                <input type="text" id="last_name" value={lastName} onChange={(e) => setLastName(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Last Name" required />
            </div>
            <div>
                <label htmlFor="pronunciation" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Pronunciation</label>
                <input type="text" id="pronunciation" value={pronunciation} onChange={(e) => setPronunciation(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div>  
            <div>
                <label htmlFor="dob" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">DOB</label>
                <input type="date" id="dob" value={dob} onChange={(e) => setDob(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required />
            </div>
            <div>
                <label htmlFor="gender" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Gender</label>
                <input type="text" id="gender" value={gender} onChange={(e) => setGender(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div>
            <div>
                <label htmlFor="diagnosis" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Diagnosis</label>
                <input type="text" id="diagnosis" value={diagnosis} onChange={(e) => setDiagnosis(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div>
            <div>
                <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Address</label>
                <input type="text" id="address" value={address} onChange={(e) => setAddress(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div>
            <div>
                <label htmlFor="allergies" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Allergies</label>
                <input type="text" id="allergies" value={allergies} onChange={(e) => setAllergies(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div>
            <div>
                <label htmlFor="pediatrician" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Pediatrician</label>
                <input type="text" id="pediatrician" value={pediatrician} onChange={(e) => setPediatrician(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div>
        </div>
        <div className="grid gap-6 mt-6 md:grid-cols-3">
            {schools.map((school, index) => (
                <div key={index}>
                <label htmlFor={`school-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">
                    School #{index + 1}
                    <button className="ml-2 px-1 rounded text-xs border-l-2 border-r-2 border-red-400 hover:bg-red-400 hover:text-white" onClick={handleDeleteSchool}>Delete School</button>
                </label>
                <input
                    id={`school-${index}`}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    type="text"
                    value={school}
                    onChange={(e) => handleSchoolChange(index, e.target.value)}
                />
                </div>
            ))}
            <div className="flex justify-center items-center">
                <button className="px-3 mt-5 rounded border-l-2 border-r-2 border-green-400 hover:bg-green-400 hover:text-white hover:font-bold" onClick={handleAddSchool}>Add School</button>
            </div>
        </div>
        <div>
            <label htmlFor="main_concern" className="block mt-6 mb-2 text-sm font-medium text-gray-900 dark:text-white">Main Concerns</label>
            <textarea id="main_concern" rows="4" value={mainConcern} onChange={(e) => setMainConcern(e.target.value)} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Answer here if applicable..."></textarea>
        </div>
        <hr className="my-12 h-0.5 border-t-0 bg-gray-700"/>
        <div className="grid gap-6 mb-6 md:grid-cols-2">
            <div>
                <label htmlFor="primary_contact" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Primary Contact</label>
                <input type="text" id="primary_contact" value={parentGuardian} onChange={(e) => setParentGuardian(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Guardian's Name" required/>
            </div>
            <div>
                <label htmlFor="relation" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Relation</label>
                <div className="">
                        <select value={relationship} onChange={(e) => setRelationship(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="relation-dropdown">
                        <option>N/A</option>
                        <option>Mother</option>
                        <option>Mom</option>
                        <option>Father</option>
                        <option>Dad</option>
                        <option>Grandmother</option>
                        <option>Grandfather</option>
                        <option>Sister</option>
                        <option>Brother</option>
                        <option>Aunt</option>
                        <option>Uncle</option>
                        <option>Step-parent</option>
                        <option>Other</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                    </div>
            </div>
            <div>
                <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Phone Number</label>
                <input type="tel" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="123-456-7890" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required />
            </div>
            <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Email</label>
                <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required />
            </div>
            <div>
                <button className="flex">Add Contact<svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="gray" viewBox="0 0 24 24">
                <path fillRule="evenodd" d="M9 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H7Zm8-1a1 1 0 0 1 1-1h1v-1a1 1 0 1 1 2 0v1h1a1 1 0 1 1 0 2h-1v1a1 1 0 1 1-2 0v-1h-1a1 1 0 0 1-1-1Z" clipRule="evenodd"/>
                </svg>
                </button>
            </div>
        </div>
        <hr className="my-12 h-0.5 border-t-0 bg-gray-700"/>
        <div className="grid gap-6 mb-6 md:grid-cols-2">
            <div className="">
                <label htmlFor="seek_services" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Services</label>
                <div className="">
                        <select value={services} onChange={(e) => setServices(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="grid-state">
                        <option>N/A</option>
                        <option>ST</option>
                        <option>OT</option>
                        <option>PG</option>
                        <option>ST & OT</option>
                        <option>ST & PG</option>
                        <option>OT & PG</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                    </div>
            </div>
            <div>
                <label htmlFor="location" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Therapy Location</label>
                <input type="text" id="location" value={therLocation} onChange={(e) => setTherLocation(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder='' required/>
            </div>
            <div>
                <label htmlFor="st_ther" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Speech Therapist</label>
                <input type="text" id="st_ther" value={stTherapist} onChange={(e) => setStTherapist(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="123-456-7890" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required />
            </div>
            <div>
                <label htmlFor="ot_ther" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Occupational Therapist</label>
                <input type="text" id="ot_ther" value={otTherapist} onChange={(e) => setOtTherapist(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required />
            </div>
        </div>
        <hr className="my-12 h-0.5 border-t-0 bg-gray-700"/>
        <div className="grid gap-6 mb-6 md:grid-cols-2">
            <div>
                <label htmlFor="insu_comp" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Insurance Company</label>
                <input type="text" id="insu_comp" value={insuranceCompany} onChange={(e) => setInsuranceCompany(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="123-456-7890" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required />
            </div>
            <div>
                <label htmlFor="verification" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">2025 Verification</label>
                <div className="">
                        <select value={fiveVerification} onChange={(e) => setFiveVerification(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="grid-state">
                        <option>N/A</option>
                        <option>Missing</option>
                        <option>In-Progress</option>
                        <option>Complete</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                    </div>
            </div>
            <div className="">
                <label htmlFor="summ_int" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">2025 Summer Interest</label>
                <div className="">
                        <select value={fiveSumInt} onChange={(e) => setFiveSumInt(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="grid-state">
                        <option>No</option>
                        <option>Yes</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                    </div>
            </div>
            <div>
                <label htmlFor="fall_int" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">2025 Fall Interest</label>
                <div className="">
                        <select value={fiveFallInt} onChange={(e) => setFiveFallInt(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="grid-state">
                        <option>No</option>
                        <option>Yes</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                    </div>
            </div>
        </div>
        <hr className="mt-12 h-0.5 border-t-0 bg-gray-700"/>
        <h2 className="">Pending Patient Information</h2>
        <div className="grid gap-6 mb-6 md:grid-cols-2">
            {!isIntakeScheduled && (
                <>
                    <div className="">
                        <label htmlFor="intake_stat" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Intake Status</label>
                        <div className="">
                                <select value={intakeStatus} onChange={(e) => setIntakeStatus(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="grid-state">
                                <option>N/A</option>
                                <option>Intake Scheduled</option>
                                <option>Another Option</option>
                                <option>Another Option</option>
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                </div>
                            </div>
                    </div> 
                    <div className="">
                        <label htmlFor="sched_with" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Intake Scheduled With</label>
                        <div className="">
                                <select value={intakeScheduleW} onChange={(e) => setIntakeScheduleW(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="grid-state">
                                <option>N/A</option>
                                <option>Michele DeSimone</option>
                                <option>Jaimie Delfino</option>
                                <option>Elizabeth Losciala</option>
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                </div>
                            </div>
                    </div> 
                    <div className="">
                        <label htmlFor="intake_date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Intake Date</label>
                        <input type="date" id="intake_date" value={intakeDate} onChange={(e) => setIntakeDate(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
                    </div>
                    <div className="">
                        <label htmlFor="intake_serv" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Inquiring Services</label>
                        <div className="">
                                <select value={inquiringServices} onChange={(e) => setInquiringServices(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="grid-state">
                                <option>N/A</option>
                                <option>ST</option>
                                <option>OT</option>
                                <option>PG</option>
                                <option>ST & OT</option>
                                <option>ST & PG</option>
                                <option>OT & PG</option>
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                </div>
                            </div>
                    </div>
                </>
            )}
            <div className="mb-6">
                <label htmlFor="firstStDate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">First ST Session Date</label>
                <input type="date" id="intake_date" value={firstStDate} onChange={(e) => setFirstStDate(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div>
            <div className="mb-6">
                <label htmlFor="firstOtDate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">First OT Session Date</label>
                <input type="date" id="intake_date" value={firstOtDate} onChange={(e) => setFirstOtDate(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div> 
        </div>
        <hr className="mb-6 h-0.5 border-t-0 bg-gray-700"/>
        <div className="mb-6">
                <label htmlFor="whereHear" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Where did you hear about us?</label>
                <div className="">
                    <select value={whereHear} onChange={(e) => setWhereHear(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="grid-state">
                        <option>N/A</option>
                        <option>Friend/Family Referral</option>
                        <option>Online Search (Google, etc.)</option>
                        <option>Physician/Healthcare Provider Referral</option>
                        <option>School/Teacher Referral</option>
                        <option>Social Media (Facebook, Instagram, Twitter, etc.)</option>
                        <option>Advertisement (Online, Print, etc.)</option>
                        <option>Website</option>
                        <option value='other'>Other (Please Specify)</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                </div>
            {whereHear === 'other' && (
                <input type='text' value={hearOther} onChange={(e) => setHearOther(e.target.value)} placeholder='Please Specify' className="mt-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
            )}
        </div>
        <form onSubmit={handleSubmit}>
        <button type="submit" className="mt-6 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
        </form>
    </div>
    );
};

export default DemographicsTab;