import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header';
import { useUser } from './UserContext';

const Alerts = () => {
  const { userName } = useUser();
  const [tasks, setTasks] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newTask, setNewTask] = useState({ task: '', date: '' });
  const [assignedTask, setAssignedTask] = useState({ alertFrom: '', alertTo: '', description: '', dueDate: '', department: '', importance: '', status: 'received' });
  const currentUser = 'John Doe'; // Replace with the actual current user's name

  useEffect(() => {
    const fetchAlerts = async () => {
      if (!userName) return;
    
      try {
        const response = await axios.post('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get_alerts', {
          user_name: userName
        });
    
        // Parse the nested body string into JSON
        const alertsData = JSON.parse(response.data.body);
        
        // Set the parsed alerts directly
        setAlerts(alertsData);
        setError(null); // Clear any existing errors
        
      } catch (error) {
        console.error('Error fetching alerts:', error);
        setError('Failed to load alerts');
      } finally {
        setLoading(false);
      }
    };
  
    fetchAlerts();
  }, [userName]);

  // Format the timestamp to relative time
  const getTimeAgo = (timestamp) => {
    const date = new Date(timestamp);
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);
    
    if (seconds < 60) return 'just now';
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) return `${minutes} minutes ago`;
    const hours = Math.floor(minutes / 60);
    if (hours < 24) return `${hours} hours ago`;
    const days = Math.floor(hours / 24);
    return `${days} days ago`;
  };

  // Handle adding a task for yourself
  const handleAddTask = async () => {
    try {
      await axios.post('/api/tasks', { ...newTask, nameFrom: currentUser, nameTo: currentUser });
      setNewTask({ task: '', date: '' });
      // Fetch updated tasks after adding a new one
      const response = await axios.get(`/api/tasks?assignedTo=${currentUser}`);
      setTasks(response.data);
    } catch (error) {
      console.error('Error adding task:', error);
    }
  };

  // Handle assigning a task to another user
  const handleAssignTask = async () => {
    try {
      await axios.post('/api/tasks', assignedTask);
      setAssignedTask({ nameFrom: '', nameTo: '', task: '', date: '', status: 'received' });
      // Optionally, you can fetch updated tasks here
    } catch (error) {
      console.error('Error assigning task:', error);
    }
  };

  // Handle updating task status
  const handleUpdateStatus = async (taskId, newStatus) => {
    try {
      await axios.put(`/api/tasks/${taskId}`, { status: newStatus });
      // Update the task status in the local state
      setTasks(tasks.map((task) => (task.id === taskId ? { ...task, status: newStatus } : task)));
    } catch (error) {
      console.error('Error updating task status:', error);
    }
  };

  return (
    <div>
        <Header />

        {/* Display alerts */}
      <div className='pt-20 pl-4'>
        <h2 className='font-bold text-blue-700 text-lg pb-2'>Your Recent Alerts</h2>
        <div className="px-4 py-6 bg-gray-50 rounded-lg shadow-sm mb-8">
          {loading ? (
            <div className="text-center">Loading alerts...</div>
          ) : error ? (
            <div className="text-red-500 text-center">{error}</div>
          ) : alerts.length === 0 ? (
            <div className="text-gray-500 text-center">No alerts found</div>
          ) : (
            <div className="space-y-4">
              {alerts.map((alert) => (
                <div key={alert.alert_id} className="bg-white p-4 rounded-md shadow-sm border border-gray-200">
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-sm font-semibold text-gray-600">
                      From: {alert.alert_from}
                    </span>
                    <span className="text-xs text-gray-400">
                      {getTimeAgo(alert.created_at)}
                    </span>
                  </div>
                  <p className="text-gray-700">{alert.description}</p>
                  <div className="mt-2 flex justify-between items-center">
                    <div className="flex gap-2"> {/* New container for importance and status */}
                      <span className={`inline-block px-2 py-1 rounded text-xs ${
                        alert.importance === 'Urgent' ? 'bg-red-200 text-red-800' :
                        alert.importance === 'High' ? 'bg-orange-100 text-orange-800' :
                        alert.importance === 'Medium' ? 'bg-yellow-100 text-yellow-800' :
                        'bg-blue-100 text-blue-800'
                      }`}>
                        {alert.importance}
                      </span>
                      <span className='inline-block px-2 py-1 rounded text-xs bg-green-100 text-green-800'>
                        {alert.status}
                      </span>
                    </div>
                    <span className="text-sm text-gray-500">
                      {alert.due_at && `Due: ${new Date(alert.due_at).toLocaleDateString()}`}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* 1. Assign tasks to yourself */}
      <div className='pt-20 pl-4'>
        <h2 className='font-bold text-blue-700 text-lg pb-2'>Your Recent Alerts {userName}</h2>
        <div className="px-4 py-6 bg-gray-50 rounded-lg shadow-sm mb-8">
        <div className="space-y-4">
          {[1, 2, 3].map((index) => (
            <div key={index} className="bg-white p-4 rounded-md shadow-sm border border-gray-200">
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm font-semibold text-gray-600">Alert {index}</span>
                <span className="text-xs text-gray-400">{index} hours ago</span>
              </div>
              <p className="text-gray-700">This is a placeholder for an alert message. Real data will appear here once available.</p>
              <div className="mt-2 flex justify-end">
                <span className="inline-block bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded">New</span>
              </div>
            </div>
          ))}
        </div>
      </div>
        </div>

      {/* 2. Assign tasks to others */}
      <div className='pl-4'>
        <h2 className='font-bold text-blue-700 text-lg pt-6 pb-2'>Send Studio Blast</h2>
        <div className="relative w-3/4 mb-4">
              <select
                name="location"
                value={assignedTask.nameTo}
                onChange={(e) => setAssignedTask({ ...assignedTask, nameTo: e.target.value })}
                id="location"
                required
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              >
                <option value="" disabled hidden>Select a department</option>
                <option value="AD">Admin</option>
                <option value="BI">Billing</option>
                <option value="PC">Parent Coordination</option>
                <option value="PV">Providers</option>
                <option value="PR">PR</option>
                <option value="AL">All</option>
              </select>
              <label
                htmlFor="location"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                To Who
              </label>
            </div>
        <div className="relative w-3/4 mb-4">
          <input type='text' id="assign_name" placeholder=" " className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
          <label htmlFor="assign_name" className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Blast Name</label>
        </div>
        <div className="relative w-3/4 mb-4">
          <textarea id="assign_desc" placeholder="" value={assignedTask.task} onChange={(e) => setAssignedTask({ ...assignedTask, task: e.target.value })} className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
          <label htmlFor="assign_desc" className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Blast Description</label>
        </div>
        <div className="relative w-3/4 mb-4">
          <input type='date' value={assignedTask.date} onChange={(e) => setAssignedTask({ ...assignedTask, date: e.target.value })} id="assign_due" placeholder=" " className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
          <label htmlFor="assign_due" className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Due Date</label>
        </div>

        
        <button className="ml-1 border-2 p-2 border-blue-400 rounded-lg w-[10rem] bg-white" onClick={handleAssignTask}>Send Blast</button>
      </div>

      {/* 3. Display tasks assigned to you */}
      <div className='pl-4'>
        
        <ul>
          {tasks.map((task) => (
            <li key={task.id}>
              <p>From: {task.nameFrom}</p>
              <p>Task: {task.task}</p>
              <p>Date: {task.date}</p>
              <p>Status: {task.status}</p>
              <select
                value={task.status}
                onChange={(e) => handleUpdateStatus(task.id, e.target.value)}
              >
                <option value="received">Received</option>
                <option value="in-progress">In Progress</option>
                <option value="completed">Completed</option>
              </select>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Alerts;